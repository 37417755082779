import React, { useEffect, useState } from "react";
import AppTextInput from "components/AppTextInput";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { downloadFileAPI } from "services/API/fileManager";
import { Modal, ModalContent } from "components/Images/ModalImage";
import EventFees from "./EventFees";

const AdminFee = ({ data }) => {
  const [values, setValues] = useState({});
  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);

  return (
    <Card style={{ marginBottom: "20px", padding: 4 }}>
      <CardHeader title={values.currency} />
      <CardContent>
        <Grid container spacing={4}>
          {values.fees &&
            values.fees.map((fee, index) => {
              return <EventFees feeData={fee} />;
            })}
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default AdminFee;
