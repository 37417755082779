import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPhysicalCardsListRequestAction } from "redux/actions/cardActions";
import { Link } from "react-router-dom";
import CustomTable from "components/CustomTable";
// --------------------------------------------------------------------------------
const PhysicalCards = ({ data }) => {
  const dispatch = useDispatch();

  const { physicalCards } = useSelector((state) => ({
    physicalCards: state.card.physicalCards,
  }));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  useEffect(() => {
    dispatch(
      getPhysicalCardsListRequestAction({
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "cardId",
        header: "Pep Card ID",
        Cell: ({ cell, row }) => (
          <Link target="_blank" to={`/cards/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "agent",
        header: "Agent",
      },
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User",
        Cell: ({ cell, row }) => (
          <Link
            target="_blank"
            to={`/app/profile/${row.original.userId}/Summary`}
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
        Cell: ({ cell, row }) => (
          <Link
            target="_blank"
            to={`/app/profile/${row.original.userId}/Summary`}
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "cardNumber",
        header: "Card Number",
        Cell: ({ cell, row }) => (
          <Link target="_blank" to={`/cards/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "brand",
        header: "Brand",
        size: 100,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 100,
      },
      {
        accessorKey: "modifiedBy",
        header: "Modified by",
        size: 100,
      },
      {
        accessorKey: "createdTime",
        header: "Created Time",
      },
      {
        accessorKey: "lastUpdate",
        header: "Last Update",
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Physical Cards"
      data={physicalCards.records}
      columns={columns}
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={physicalCards.count}
    />
  );
};

export default PhysicalCards;
