import React, { useEffect, useState } from "react";
import { boot, CurrencyFlags } from "constants/constants";
import MainTable from "components/MainTable";
import useAPI from "services/hook";
import APIs from "services/API/ProfileUser";
import { useDispatch } from "react-redux";
import {
  editWalletMinimumBalanceRequestAction,
  getUserWallet,
} from "redux/actions/usersActions";
import CustomTable from "components/CustomTable";

const UserBalance = ({ userId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lookup, setLookup] = useState(null);

  useEffect(() => {
    if (boot.currencies && boot.currencies.length > 1) {
      const obj2 = boot.currencies.reduce((acc, cur) => {
        acc[cur.id] = cur.code;
        return acc;
      }, {});

      setLookup(obj2);
    }
  }, [boot.currencies]);

  useEffect(() => {
    // if (lookup) fetchData();
    if (userId) dispatch(getUserWallet({ setData, userId }));
  }, [userId]);

  // const apiGetBalance = useAPI(APIs.apiGetBalance);
  // const fetchData = async () => {
  //   const result = await apiGetBalance.request({ userId });
  //   if (result) {
  //     var formatedData = result?.map((item) => {
  //       item.websiteLogo = ApiURL + item.website_logo;
  //       return item;
  //     });
  //     setData(formatedData);
  //   }
  //   setLoading(false);
  // };

  const columns = [
    {
      header: "Currency",
      accessorKey: "currency",
      Cell: ({ cell, row }) => (
        <>
          <div className="jr-profile-banner-top-left">
            {/* <div className="jr-profile-banner-avatar">
              <i className={`flag flag-24 flag-${rowData.currency}`} />
            </div> */}
            <img
              src={CurrencyFlags[row.original.currency]}
              width={20}
              className="mr-2"
            />
            <div className="jr-profile-banner-avatar-info">
              {row.original.currency}
            </div>
          </div>
        </>
      ),
      enableEditing: false,
    },
    {
      header: "Wallet ID",
      accessorKey: "identification",
      enableEditing: false,
    },
    {
      header: "Balance",
      accessorKey: "balance",
      enableEditing: false,
    },
    {
      header: "Pending",
      accessorKey: "pending",
      enableEditing: false,
    },
    {
      header: "Minimum Balance",
      accessorKey: "minimumBalance",
    },
  ];

  const options = {
    actionsColumnIndex: columns.length,
  };

  const apiCreateBalance = useAPI(APIs.apiCreateBalance, true);
  const addRow = async (newData, updatedData) => {
    const result = await apiCreateBalance.request({
      userId,
      currency_id: newData.currency_id,
    });
    if (result) setData([...updatedData]);
  };

  const apiDeleteBalance = useAPI(APIs.apiDeleteBalance, true);
  const deleteRow = async (currency_id, updatedData) => {
    const result = await apiDeleteBalance.request({ userId, currency_id });
    if (result) setData([...updatedData]);
  };

  return (
    // <div className="card">
    //   <MainTable
    //     loading={loading}
    //     customOptions={options}
    //     columns={columns}
    //     data={data}
    //     title="Balance"
    //     onRowAdd={(newData) =>
    //       new Promise((resolve) => {
    //         setTimeout(() => {
    //           const updatedData = [...data];
    //           updatedData.push(newData);
    //           addRow(newData, updatedData);
    //           resolve();
    //         }, 600);
    //       })
    //     }
    //     onRowUpdate={(newData, oldData) =>
    //       new Promise((resolve) => {
    //         setTimeout(() => {
    //           const updatedData = [...data];
    //           updatedData[data.indexOf(oldData)] = newData;
    //           // updateRow(oldData.id, newData.status, updatedData);
    // dispatch(
    //   editWalletMinimumBalanceRequestAction({
    //     walletId: newData.id,
    //     minBalance: newData.minimumBalance,
    //     setData,
    //     userId,
    //   })
    //           );
    //           resolve();
    //         }, 600);
    //       })
    //     }
    //   />
    // </div>
    <CustomTable
      label="Wallets"
      columns={columns}
      data={data}
      enableEditing
      onEditingRowSave={({ values, table }) => {
        let editedRow = data.find(
          (item) => item.identification === values.identification
        );
        dispatch(
          editWalletMinimumBalanceRequestAction({
            walletId: editedRow.id,
            minBalance: values.minimumBalance,
            setData,
            userId,
          })
        );
        table.setEditingRow(null);
      }}
    />
  );
};

export default UserBalance;
