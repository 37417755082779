import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import { CustomerReducer } from "./reducers/Customer";
import settings from "./reducers/Settings";
import MessageDialog from "./reducers/Dialog";
//import { reducer as reduxFormReducer } from 'redux-form';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import { TopUpReducer } from "./reducers/TopUpReducer";
import { ExchangeReducer } from "./reducers/ExchangeReducer";
import { AgentReducer } from "./reducers/agentReducer";
import { CardReducer } from "./reducers/cardReducer";
import { LogReducer } from "./reducers/logReducer";
import { TopUpAgentReducer } from "./reducers/TopUpAgentReducer ";
// --------------------------------------------------------------------------------
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth: AuthReducer,
  topUp: TopUpReducer,
  agentTopup: TopUpAgentReducer,
  exchange: ExchangeReducer,
  customer: CustomerReducer,
  agent: AgentReducer,
  card: CardReducer,
  log: LogReducer,
  settings,
  MessageDialog,
});

const persistConfig = {
  timeout: 0,
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  whitelist: ["auth", "settings"],
  transforms: [createWhitelistFilter("auth", ["token", "user"])],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  composeEnhancers(middleware)
);

export const persistor = persistStore(store);
