"use strict";

/**
 * Non-public global class to handle the "default" FlashMessage instance to global use
 */
class ModalManager {
  _defaultMessageBar = null;
  register(_ref) {
    console.log("_ref", _ref);
    console.log(!this._defaultMessageBar && "_id" in _ref);
    if (!this._defaultMessageBar && "_id" in _ref) {
      this._defaultMessageBar = _ref;
    }
  }
  unregister(_ref) {
    if (!!this._defaultMessageBar && this._defaultMessageBar._id === _ref._id) {
      this._defaultMessageBar = null;
    }
  }
  getDefault() {
    return this._defaultMessageBar;
  }
}

export default new ModalManager();
