import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteDefaultWalletRequestAction } from "redux/actions/agentActions";
import { editAgentDefaultWalletRequestAction } from "./../../../../../redux/actions/agentActions";

const FeesCard = ({ wallet, currencies }) => {
  const dispatch = useDispatch();

  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const renderConfirmDialog = (
    <Dialog
      open={showConfirmDialog}
      onClose={() => setShowConfirmDialog(false)}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogTitle>Are you sure?</DialogTitle>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setShowConfirmDialog(false)}
          fullWidth
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() =>
            dispatch(deleteDefaultWalletRequestAction({ id: wallet.id }))
          }
          fullWidth
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={currencies.map((currency) => currency.code)}
                getOptionLabel={(option) => option}
                defaultValue={wallet.currencies}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label="Wallet" />
                )}
                onChange={(e, c) => setSelectedCurrencies(c)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Agent"
                value={wallet.agent}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setShowConfirmDialog(true)}
              color="error"
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() =>
                dispatch(
                  editAgentDefaultWalletRequestAction({
                    id: wallet.id,
                    currencies: selectedCurrencies,
                  })
                )
              }
            >
              Save
            </Button>
          </Grid>
        </CardActions>
      </Card>
      {renderConfirmDialog}
    </>
  );
};

export default FeesCard;
