import {
  GET_PEP_LOGS_FAILURE,
  GET_PEP_LOGS_STARTED,
  GET_PEP_LOGS_SUCCESS,
  GET_USERS_LOG_FAILURE,
  GET_USERS_LOG_STARTED,
  GET_USERS_LOG_SUCCESS,
  LOGIN_DEFAULT,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_DEFAULT,
  usersLog: { records: [], count: 0 },
  pepLogs: { records: [], count: 0 },
  error: "",
};
export const LogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_LOG_STARTED:
    case GET_PEP_LOGS_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_USERS_LOG_SUCCESS:
      return {
        ...state,
        rType: GET_USERS_LOG_SUCCESS,
        usersLog: action.payload.usersLog,
        error: "",
      };
    case GET_PEP_LOGS_SUCCESS:
      return {
        ...state,
        rType: GET_USERS_LOG_SUCCESS,
        pepLogs: action.payload.pepLogs,
        error: "",
      };
    case GET_USERS_LOG_FAILURE:
    case GET_PEP_LOGS_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
