import FormGenerator from "components/FormGenerator/index";
import MessageDialog, {
  INFORMATION_DIALOG,
} from "components/MessageDialog/index";
import React, { useEffect, useState } from "react";
import APIs from "services/API";
import useAPI from "services/hook";
import { formItems } from "./formItems";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { CardContent, CardHeader } from "@mui/material";

const AddAdmin = ({ location }) => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    roleID: [],
    agentID : [],
  });
  const [loading, setLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    type: INFORMATION_DIALOG,
  });
  const [error , setError] = useState();

  const apiCreateAdmin = useAPI(APIs.apiCreateAdmin);
  const apiEditAdmin = useAPI(APIs.apiEditAdmin);
  const apiUpdateAdmin = useAPI(APIs.apiUpdateAdmin);


  useEffect(() => {
    if (location?.state?.id) getAdminDetail(location?.state?.id);
  }, []);

  const getAdminDetail = async (id) => {
    setLoading(true);
    const result = await apiEditAdmin.request({ id });
    if (result) setInitialValues(result);
    setLoading(false);
  };

  const fetchData = async (values) => {
  
    setLoading(true);

    let expire_at = values?.expire_at
      ? moment(new Date(values?.expire_at)).format("YYYY-MM-DD 23:59:59")
      : null;

    let formData = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      mobile: values?.mobile,
      email: values?.email,
      password: values?.password,
      roleID: values?.roleID,
      agentID : values?.agentID
      // expire_at,
    };

    let result = null;
  
    
    if (location?.state?.id) {
      formData.id = location?.state?.id;
      result = await apiUpdateAdmin.request(formData);
     
     
    } else {
      result = await apiCreateAdmin.request(formData);
     
    }
    
    if (result) setDialogProps({ isOpen: true });
    setLoading(false);
  };
  return (
    <Card>
      <CardHeader title="Add Admin" />
      <CardContent>
        <FormGenerator
          formItems={formItems}
          initialValues={initialValues}
          customClassName="justify-content-center"
          onSubmit={fetchData}
          onCancel={location?.state?.id ? () => navigate(-1) : null}
          loading={loading}
          
        />
      </CardContent>
      {dialogProps.isOpen && (
        <MessageDialog
          title={"Done successfully"}
          onConfirm={() => {
            setDialogProps({ isOpen: false });
            navigate("/admin/list");
          }}
        />
      )}
    </Card>
  );
};

export default AddAdmin;
