import {
  GET_AGENTS_LIST_FAILURE,
  GET_AGENTS_LIST_STARTED,
  GET_AGENTS_LIST_SUCCESS,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: null,
  agentsList: { records: [] },
  error: "",
};
export const AgentReducer = (state = initialState, action) => {
  console.log(action.type)
  switch (action.type) {
    case GET_AGENTS_LIST_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_AGENTS_LIST_SUCCESS:
       console.log({
         ...state,
         rType: GET_AGENTS_LIST_SUCCESS,
         agentsList: action.payload.agentsList,
         error: "",
       });
       
      return {
        ...state,
        rType: GET_AGENTS_LIST_SUCCESS,
        agentsList: action.payload.agentsList,
        error: "",
      };
     
    case GET_AGENTS_LIST_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
