import {
  GET_PEP_LOGS_FAILURE,
  GET_PEP_LOGS_STARTED,
  GET_PEP_LOGS_SUCCESS,
  GET_USERS_LOG_FAILURE,
  GET_USERS_LOG_STARTED,
  GET_USERS_LOG_SUCCESS,
} from "redux/ActionTypes";
import { getPepLogsAPI, getUsersLogAPI } from "services/API/logs";
// --------------------------------------------------------------------------------
export const getUsersLogRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getUsersLogStartedAction());
    try {
      let response = await getUsersLogAPI({ limit, offset });
      if (!response.error) {
        dispatch(getUsersLogSuccessAction({ usersLog: response.data }));
      } else {
        dispatch(getUsersLogFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersLogFailureAction(error.error_description));
    }
  };
};

export const getUsersLogStartedAction = () => ({
  type: GET_USERS_LOG_STARTED,
});

export const getUsersLogSuccessAction = (payload) => ({
  type: GET_USERS_LOG_SUCCESS,
  payload,
});

export const getUsersLogFailureAction = (message) => ({
  type: GET_USERS_LOG_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getPepLogsRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getPepLogsStartedAction());
    try {
      let response = await getPepLogsAPI({ limit, offset });
      if (!response.error) {
        dispatch(getPepLogsSuccessAction({ pepLogs: response.data }));
      } else {
        dispatch(getPepLogsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getPepLogsFailureAction(error.error_description));
    }
  };
};

export const getPepLogsStartedAction = () => ({
  type: GET_PEP_LOGS_STARTED,
});

export const getPepLogsSuccessAction = (payload) => ({
  type: GET_PEP_LOGS_SUCCESS,
  payload,
});

export const getPepLogsFailureAction = (message) => ({
  type: GET_PEP_LOGS_FAILURE,
  payload: message,
});
