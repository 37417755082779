import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
  FormHelperText
} from "@mui/material";
import {
  getUserWallet,
  topUpRequestAction,
  uploadFileRequestAction,
} from "redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "components/Autocomplete";
import DropDown from "components/DropDown";
import { hideMessage, showMessage } from "components/Modal";
import AppNumberInput from "components/AppNumberInput";
import {
  removeBalanceSeparator,
  toFixedWithoutZeros,
} from "constants/usefulFunctions";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { API_GET_SEARCH, token } from "constants/constants";
import debounce from "lodash/debounce";
import CustomFileUploader from "components/CustomFileUploader";
import { calculateFeeRequestAction } from "redux/actions/GeneralActions";
import CloseIcon from "@mui/icons-material/Close";
import { getAdminBalanceAction, getAdminWallet } from "redux/actions/adminActions";

// --------------------------------------------------------------------------------
const TopUpUser = ({ data }) => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("0.0");
  const [commission, setCommission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [file, setFile] = useState(null);
  const [userWallets, setUserWallets] = useState([]);
  const [wallet, setWallet] = useState("");
  const [userLimits, setUserLimits] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [adminBalance , setAdminBalance] = useState();
  const [minBalance , setMinBalance] = useState(0.0);
  const [balanceChanged , setBalanceChanged] = useState(false);

  
  const topUpFee = (
    parseFloat(userLimits.userFee) +
    (parseFloat(userLimits.userPercentFee) / 100) * amount
  ).toFixed(2);
  
const authToken = useSelector((state) => {
     return state.auth.token;
   });

  // useEffect(() => {
  //   dispatch(getCurrenciesRequestAction({ setCurrencies }));
  // }, []);

  useEffect(() => {
    if (inputValue.length > 3) request();
  }, [inputValue]);

  

  useEffect(() => {
    if ("id" in value && wallet)
      dispatch(
        calculateFeeRequestAction({
          eventId: 26,
          userId: value.id,
          walletId: wallet,
          setUserLimits,
        })
      );
      dispatch(
        getAdminBalanceAction(wallet,setAdminBalance)
      )
  }, [value, wallet]);


   useEffect(() => {
    if (userWallets.length > 0) {
      console.log(userWallets)
      const userWallet = userWallets.find(item => item.id === wallet);
      console.log(userWallet)
      if (userWallet) {
        console.log(userWallet)
        setMinBalance(userWallet.minimumBalance);
      }
    }
  }, [userWallets, wallet]);


  const handleChange = debounce((newValue) => {
    setInputValue(newValue);
  }, 400);

  const request = () => {
   if (!loading) setLoading(true);
   axios
     .get(`${API_GET_SEARCH}?keyword=${String(inputValue)}`, {
       headers: {
         Authorization: `Bearer ${authToken}`,
       },
     })
     .then((res) => {
       setLoading(false);
       setOptions(res.data.data);
     })
     .catch((error) => {
       setLoading(false);
       console.error("Error fetching data:", error);
     });
 };
  
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option.firstName} ${option.lastName}` +
      option.email +
      option.mobile?.toString() +
      option.iban?.toString() +
      option.website_domain +
      option?.channel_name,
  });

  const handleClose = () => {
    setShowConfirm(false);
  };
  

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    setBalanceChanged(true);
  }
  

 
  const getErrorMessage = () => {
   if (!balanceChanged) return null;

    switch (true) {
      case amount >= adminBalance:
        return "Insufficient balance";
      case amount < +userLimits.min : 
       return `Please enter an amount bigger than or equal to ${userLimits.min}${userLimits.symbol}.`;

      case amount > +userLimits.max : 
       return `Please enter an amount less than or equal to ${userLimits.max}${userLimits.symbol}.`;

      case ((amount - topUpFee)) > +(userLimits.max) ||  ((amount - topUpFee)) < +(userLimits.min) :
       return `The charged amount is out of the acceptable limits!`; 

      default:
        return null;
    }
  };

  const errorMessage = getErrorMessage();
  const hasError = errorMessage !== null;

 

  const renderDialogConfirm = (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showConfirm}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Top-up Wallet
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          User's Full Name:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {value.firstName} {value.lastName}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Email:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {value.email}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          Amount:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {toFixedWithoutZeros(amount)}
            {userLimits.symbol}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Fee:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {toFixedWithoutZeros(topUpFee)}
            {userLimits.symbol}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Charged Amount:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block"
           }}>
            {toFixedWithoutZeros((amount - topUpFee))}
            {userLimits.symbol}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Description:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {desc}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ boxShadow: "none" }}
          // onClick={handleClose}
          onClick={() =>
            dispatch(
              topUpRequestAction({
                userId: value.id,
                walletId: wallet,
                amount: removeBalanceSeparator(amount),
                description: desc,
                file,
                setLoading,
                onSuccess: () =>
                  showMessage({
                    status: "success",
                    description: "Success",
                    onSubmit: () => {
                      //handleChargedAmount((amount - topUpFee))
                      hideMessage();
                      navigate("/top-up/list");
                    },
                  }),
              })
            )
          }
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Card>
      <CardHeader title="Top-up Wallet" />
      <CardContent >
        <Grid container spacing={3} sx={{position:'relative'}}>
          <Grid item xs={12} md={4} lg={3}>
            <div
              className={`searchbox right-side-icon bg-transparent d-block`}
              // style={{ width: "320px", maxWidth: "420px" }}
            >
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                options={options}
                getOptionLabel={(option) => (option.email ? option.email : "")}
                loading={loading}
                filterOptions={filterOptions}
                renderOption={(e, option) => {
                  return (
                    <div
                      key={option.id}
                      onClick={(v) => {
                        // e.onClick(v);
                        // navigate(`/app/profile/${String(option.id)}/Summary`);
                        setValue(option);
                        dispatch(
                          getUserWallet({
                            userId: option.id,
                            setData: setUserWallets,
                          })
                        );
                      }}
                      {...e}
                      className={`${e.className} option-container`}
                    >
                      <div>
                        <span>Name: </span>
                        {String(option.firstName) +
                          " " +
                          String(option.lastName)}
                      </div>
                      {/* <div>
                      <span>Mobile:</span> {String(option.mobile)}
                    </div> */}
                      <div>
                        <span>Email:</span> {String(option.email)}
                      </div>
                    </div>
                  );
                }}
                onInputChange={(_, newValue) => {
                  handleChange(newValue);
                }}
                onChange={(_, newValue) => {
                  setValue(newValue);
                  dispatch(
                    getUserWallet({
                      userId: newValue.id,
                      setData: setUserWallets,
                    })
                  );
                  setLoading(false);
                }}
                value={value}
                disablePortal
                clearOnEscape
                autoHighlight
                blurOnSelect
                selectOnFocus
                handleHomeEndKeys
                onBlur={() => {
                  setInputValue("");
                  setLoading(false);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="User"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <div
                            style={{
                              backgroundColor: "white",
                              height: 36,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {loading && (
                              <CircularProgress color="primary" size={30} />
                            )}

                            {params.InputProps.endAdornment}
                          </div>
                        ),
                      }}
                    />
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <DropDown
              label="Wallet"
              schema={{ label: "currency", value: "id" }}
              items={userWallets}
              onChange={(event) =>{
                 console.log(event.target.value)
                 setWallet(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <AppNumberInput
              thousandSeparator={true}
              label="Amount"
              onChange={handleAmountChange}
              value={amount}
              fullWidth
              decimalScale={2}
              error={hasError}
              helperText={hasError ? errorMessage : ''}
              maxLength={19}
              sx={{ boxSizing: 'border-box' }}
            />
             
         
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              label="Description"
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12} md={4} lg={3}>
            <AppNumberInput
              thousandSeparator={true}
              label="Fees"
              onChange={(e) => setFee(e.target.value)}
              value={fee}
              fullWidth
              decimalScale={2}
            />
          </Grid> */}
         <Grid container spacing={2} sx={{ position: 'absolute', top:'7rem', left: '2rem' }}>
           <Grid
            item
            xs={12}
            sx={{ visibility: userLimits ? "visible" : "hidden" }}
          >
            <Typography sx={{ display: "inline" }}>
              Wallet topup fee:{" "}
            </Typography>
            <Typography sx={{ display: "inline", fontWeight: 600 }}>
              {Number(userLimits.userPercentFee) > 0 &&
              Number(userLimits.userFee) > 0
                ? `${toFixedWithoutZeros(userLimits.userFee)}${
                    userLimits.symbol
                  } + ${toFixedWithoutZeros(
                    userLimits.userPercentFee
                  )}% = ${toFixedWithoutZeros(topUpFee)}${userLimits.symbol}`
                : Number(userLimits.userFee) === 0 &&
                  Number(userLimits.userPercentFee) > 0
                ? `${toFixedWithoutZeros(topUpFee)}${
                    userLimits.symbol
                  } (${toFixedWithoutZeros(userLimits.userPercentFee)}%)`
                : Number(userLimits.userPercentFee) > 0
                ? `${toFixedWithoutZeros(userLimits.userPercentFee)}% `
                : `${toFixedWithoutZeros(topUpFee)}${userLimits.symbol}`}
            </Typography>
          </Grid>
        

            <Grid item
              xs={12} sx={{ visibility: userLimits ? "visible" : "hidden" }}
           >
            <Typography sx={{ display: "inline" }}>
              Admin Balance :{" "}
            </Typography>
            <Typography sx={{ display: "inline", fontWeight: 600 }}>
               {/* {parseFloat(adminBalance).toFixed(2) } {userLimits.symbol} */}
               {toFixedWithoutZeros(adminBalance)} {userLimits.symbol}
            </Typography>
          </Grid>

           <Grid item
              xs={12} sx={{ visibility: userLimits ? "visible" : "hidden" }}
           >
            <Typography sx={{ display: "inline" }}>
              Minimum Amount:{" "}
            </Typography>
            <Typography sx={{ display: "inline", fontWeight: 600 }}>
               {toFixedWithoutZeros(userLimits.min) } {userLimits.symbol}
            </Typography>
          </Grid>

          <Grid item
              xs={12} sx={{ visibility: userLimits ? "visible" : "hidden" }}
           >
            <Typography sx={{ display: "inline" }}>
              Maximum Amount:{" "}
            </Typography>
            <Typography sx={{ display: "inline", fontWeight: 600 }}>
               {userLimits.max } {userLimits.symbol}
            </Typography>
          </Grid>

         </Grid>
         
          <Grid item xs={12} md={4} mt={20} lg={3}>
            <CustomFileUploader
              onChange={({ event, setUploadPercent, onSuccess }) => {
                let file = event.target.files[0];
                let formData = new FormData();
                formData.append("topup_receipt", file);
                dispatch(
                  uploadFileRequestAction({
                    formData,
                    setUploadPercent,
                    onSuccess: (data) => {
                      onSuccess();
                      setFile(data);
                    },
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <LoadingButton
          variant="contained"
          // loading={loading}
          onClick={() => setShowConfirm(true)}
          disabled={
             !value.id ||
             !wallet ||
              desc.length === 0 ||
              ((amount - topUpFee)) > +(userLimits.max) ||
              ((amount - topUpFee)) < +(userLimits.min) ||
              Number(amount) >= adminBalance 
          }
        >
          Submit
        </LoadingButton>
      </CardActions>
      {renderDialogConfirm}
    </Card>
  );
};

export default TopUpUser;
