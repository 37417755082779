import { hideMessage, showMessage } from "components/Modal";
import {
  GET_PHYSICAL_CARDS_LIST_FAILURE,
  GET_PHYSICAL_CARDS_LIST_STARTED,
  GET_PHYSICAL_CARDS_LIST_SUCCESS,
  GET_VIRTUAL_CARDS_LIST_FAILURE,
  GET_VIRTUAL_CARDS_LIST_STARTED,
  GET_VIRTUAL_CARDS_LIST_SUCCESS,
} from "redux/ActionTypes";
import {
  getCardsLimitsAPI,
  getCardsStatusAPI,
  getPhysicalCardsListAPI,
  getVirtualCardsListAPI,
  setCardsLimitsAPI,
  updateCardsStatusAPI,
} from "services/API/card";
// --------------------------------------------------------------------------------
export const getPhysicalCardsListRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getPhysicalCardsListStartedAction());
    try {
      let response = await getPhysicalCardsListAPI({ limit, offset });
      if (!response.error) {
        dispatch(
          getPhysicalCardsListSuccessAction({ physicalCards: response.data })
        );
      } else {
        dispatch(
          getPhysicalCardsListFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getPhysicalCardsListFailureAction(error.error_description));
    }
  };
};

export const getPhysicalCardsListStartedAction = () => ({
  type: GET_PHYSICAL_CARDS_LIST_STARTED,
});

export const getPhysicalCardsListSuccessAction = (payload) => ({
  type: GET_PHYSICAL_CARDS_LIST_SUCCESS,
  payload,
});

export const getPhysicalCardsListFailureAction = (message) => ({
  type: GET_PHYSICAL_CARDS_LIST_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getVirtualCardsListRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getVirtualCardsListStartedAction());
    try {
      let response = await getVirtualCardsListAPI({ limit, offset });
      if (!response.error) {
        dispatch(
          getVirtualCardsListSuccessAction({ virtualCards: response.data })
        );
      } else {
        dispatch(
          getVirtualCardsListFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getVirtualCardsListFailureAction(error.error_description));
    }
  };
};
export const getCardsLimitsRequestAction = (cardId, setData) => {
  return async (dispatch) => {
    try {
      let response = await getCardsLimitsAPI(cardId);
      if (!response.error) {
        setData(response.data);
      }
    } catch (error) {}
  };
};
export const setCardsLimitsRequestAction = (data, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      let response = await setCardsLimitsAPI(data);
      if (!response.error) {
        showMessage({
          onSubmit: () => {
            hideMessage();
          },
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
        onSubmit: () => {
          hideMessage();
        },
      });
    }
  };
};
export const updateCardsStatusRequestAction = ({
  data,
  onSuccess,
  setLoading,
}) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      let response = await updateCardsStatusAPI(data);
      onSuccess();
      if (!response.error) {
        showMessage({
          onSubmit: () => {
            hideMessage();
          },
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
        onSubmit: () => {
          hideMessage();
        },
      });
    }
  };
};
export const getCardsStatusRequestAction = (cardId, setData) => {
  return async (dispatch) => {
    try {
      let response = await getCardsStatusAPI(cardId);
      if (!response.error) {
        setData(response.data);
      }
    } catch (error) {
      showMessage({
        description: error.error_description.message,
        status: "error",
        onSubmit: () => {
          hideMessage();
        },
      });
    }
  };
};

export const getVirtualCardsListStartedAction = () => ({
  type: GET_VIRTUAL_CARDS_LIST_STARTED,
});

export const getVirtualCardsListSuccessAction = (payload) => ({
  type: GET_VIRTUAL_CARDS_LIST_SUCCESS,
  payload,
});

export const getVirtualCardsListFailureAction = (message) => ({
  type: GET_VIRTUAL_CARDS_LIST_FAILURE,
  payload: message,
});
