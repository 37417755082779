import React, { useEffect, useState } from "react";
import AppTextInput from "components/AppTextInput";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { setUserFeesRequestAction } from "redux/actions/usersActions";
import AppNumberInput from "components/AppNumberInput";
import {
  camelCaseToTitleCase,
  removeBalanceSeparator,
} from "constants/usefulFunctions";

const EventFees = ({ feeData }) => {
  const dispatch = useDispatch();
  const [fee, setFee] = useState(feeData);
  const [loading, setLoading] = useState(false);

  return (
    <Grid item md={4}>
      <Card
        style={{
          margin: 4,
        }}
      >
        <CardHeader
          title={camelCaseToTitleCase(fee["event"])}
          subheader={fee["type"] ? camelCaseToTitleCase(fee["type"]) : "‌"}
          titleTypographyProps={{ sx: { fontSize: 18 } }}
        />
        <CardContent>
          <Grid container spacing={2}>
            {fee &&
              Object.keys(fee).map((item) => {
                if (item !== "type" && item !== "event" && item !== "id") {
                  return (
                    <Grid item xs={12}>
                      <AppNumberInput
                        label={item}
                        thousandSeparator={true}
                        value={fee[item]}
                        onChange={(e) =>
                          setFee({
                            ...fee,
                            [item]: removeBalanceSeparator(e.target.value),
                          })
                        }
                        fullWidth
                        dir="ltr"
                      />
                    </Grid>
                  );
                }
              })}
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <LoadingButton
            variant="contained"
            onClick={() => {
              dispatch(setUserFeesRequestAction(fee, setLoading, setFee));
            }}
            loading={loading}
            fullWidth
          >
            Submit
          </LoadingButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default EventFees;
