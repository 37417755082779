import { hideMessage, showMessage } from "components/Modal";
import {
  CHANGE_STATUS_KYC_FIELD_FAILURE,
  CHANGE_STATUS_KYC_FIELD_STARTED,
  CHANGE_STATUS_KYC_FIELD_SUCCESS,
  CONFIRM_KYC_FAILURE,
  CONFIRM_KYC_STARTED,
  CONFIRM_KYC_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_STARTED,
  GET_ALL_USERS_SUCCESS,
  GET_APPROVED_USERS_FAILURE,
  GET_APPROVED_USERS_STARTED,
  GET_APPROVED_USERS_SUCCESS,
  GET_KNOW_YOUR_CUSTOMER_FAILURE,
  GET_KNOW_YOUR_CUSTOMER_STARTED,
  GET_KNOW_YOUR_CUSTOMER_SUCCESS,
  GET_PENDING_USERS_FAILURE,
  GET_PENDING_USERS_STARTED,
  GET_PENDING_USERS_SUCCESS,
  GET_PREMIUM_USERS_FAILURE,
  GET_PREMIUM_USERS_STARTED,
  GET_PREMIUM_USERS_SUCCESS,
  GET_STARTER_USERS_FAILURE,
  GET_STARTER_USERS_STARTED,
  GET_STARTER_USERS_SUCCESS,
  MAKE_PREMIUM_USER_FAILURE,
  MAKE_PREMIUM_USER_STARTED,
  MAKE_PREMIUM_USER_SUCCESS,
  MAKE_STARTER_USER_FAILURE,
  MAKE_STARTER_USER_STARTED,
  MAKE_STARTER_USER_SUCCESS,
} from "../ActionTypes";
import {
  changeStatusKycFieldAPI,
  confirmKycAPI,
  getAllUsersAPI,
  getApprovedUsersAPI,
  getDeclinedUsersAPI,
  getKnowYourCustomerAPI,
  getPendingUsersAPI,
  getPremiumUsersAPI,
  getStarterUsersAPI,
  makePremiumUserAPI,
  makeStarterUserAPI,
} from "services/API/customer";
// --------------------------------------------------------------------------------
export const getAllUsersRequestAction = ({
  limit,
  offset,
  from,
  to,
  agent,
}) => {
  return async (dispatch) => {
    dispatch(getAllUsersStartedAction());
    try {
      let response = await getAllUsersAPI({ limit, offset, from, to, agent });
      if (!response.error) {
        dispatch(getAllUsersSuccessAction({ allUsers: response.data }));
      } else {
        dispatch(getAllUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getAllUsersFailureAction(error.error_description));
    }
  };
};

export const getAllUsersStartedAction = () => ({
  type: GET_ALL_USERS_STARTED,
});

export const getAllUsersSuccessAction = (payload) => ({
  type: GET_ALL_USERS_SUCCESS,
  payload,
});

export const getAllUsersFailureAction = (message) => ({
  type: GET_ALL_USERS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getPendingUsersRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getPendingUsersStartedAction());
    try {
      let response = await getPendingUsersAPI({ limit, offset });
      if (!response.error) {
        dispatch(getPendingUsersSuccessAction({ pendingUsers: response.data }));
      } else {
        dispatch(getPendingUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getPendingUsersFailureAction(error.error_description));
    }
  };
};

export const getPendingUsersStartedAction = () => ({
  type: GET_PENDING_USERS_STARTED,
});

export const getPendingUsersSuccessAction = (payload) => ({
  type: GET_PENDING_USERS_SUCCESS,
  payload,
});

export const getPendingUsersFailureAction = (message) => ({
  type: GET_PENDING_USERS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getApprovedUsersRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getApprovedUsersStartedAction());
    try {
      let response = await getApprovedUsersAPI({ limit, offset });
      if (!response.error) {
        dispatch(
          getApprovedUsersSuccessAction({ approvedUsers: response.data })
        );
      } else {
        dispatch(
          getApprovedUsersFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getApprovedUsersFailureAction(error.error_description));
    }
  };
};
export const getDeclinedUsersRequestAction = ({ setData, limit, offset }) => {
  return async (dispatch) => {
    // dispatch(getApprovedUsersStartedAction());
    try {
      let response = await getDeclinedUsersAPI({ limit, offset });
      if (!response.error) {
        setData(response.data);
        // dispatch(
        //   getApprovedUsersSuccessAction({ approvedUsers: response.data })
        // );
      } else {
        // dispatch(
        //   getApprovedUsersFailureAction(response.data.error_description)
        // );
      }
    } catch (error) {
      // dispatch(getApprovedUsersFailureAction(error.error_description));
    }
  };
};

export const getApprovedUsersStartedAction = () => ({
  type: GET_APPROVED_USERS_STARTED,
});

export const getApprovedUsersSuccessAction = (payload) => ({
  type: GET_APPROVED_USERS_SUCCESS,
  payload,
});

export const getApprovedUsersFailureAction = (message) => ({
  type: GET_APPROVED_USERS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getKnowYourCustomerRequestAction = ({ customerId }) => {
  return async (dispatch) => {
    dispatch(getKnowYourCustomerStartedAction());
    try {
      let response = await getKnowYourCustomerAPI({ customerId });
      if (!response.error) {
        dispatch(
          getKnowYourCustomerSuccessAction({
            kycFields: response.data.kyc,
            pepAccountStatus: response.data.pepAccountStatus,
            userStatus: response.data.userStatus,
            email: response.data.email,
            mobile: response.data.mobile,
          })
        );
      } else {
        dispatch(
          getKnowYourCustomerFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getKnowYourCustomerFailureAction(error.error_description));
    }
  };
};

export const getKnowYourCustomerStartedAction = () => ({
  type: GET_KNOW_YOUR_CUSTOMER_STARTED,
});

export const getKnowYourCustomerSuccessAction = (payload) => ({
  type: GET_KNOW_YOUR_CUSTOMER_SUCCESS,
  payload,
});

export const getKnowYourCustomerFailureAction = (message) => ({
  type: GET_KNOW_YOUR_CUSTOMER_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const changeStatusKycFieldRequestAction = ({
  id,
  status,
  description,
  customerId,
  setIsDeclined,
  setEdit,
  setLoading,
}) => {
  return async (dispatch) => {
    dispatch(changeStatusKycFieldStartedAction());
    setLoading(true);
    try {
      let response = await changeStatusKycFieldAPI({ id, status, description });
      if (!response.error) {
        dispatch(changeStatusKycFieldSuccessAction());
        dispatch(getKnowYourCustomerRequestAction({ customerId }));
        setIsDeclined(false);
        setEdit(false);
        setLoading(false);
      } else {
        dispatch(
          changeStatusKycFieldFailureAction(response.data.error_description)
        );
        setLoading(false);
      }
    } catch (error) {
      dispatch(changeStatusKycFieldFailureAction(error.error_description));
      setLoading(false);
    }
  };
};

export const changeStatusKycFieldStartedAction = () => ({
  type: CHANGE_STATUS_KYC_FIELD_STARTED,
});

export const changeStatusKycFieldSuccessAction = (payload) => ({
  type: CHANGE_STATUS_KYC_FIELD_SUCCESS,
  payload,
});

export const changeStatusKycFieldFailureAction = (message) => ({
  type: CHANGE_STATUS_KYC_FIELD_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const confirmKycRequestAction = ({
  userId,
  navigate,
  setShowConfirmDialog,
}) => {
  return async (dispatch) => {
    dispatch(confirmKycStartedAction());
    try {
      let response = await confirmKycAPI({
        userId,
      });
      if (!response.error) {
        if (response.data.userStatus === "Approved") {
          navigate("/users/all-users");
        } else if (response.data.userStatus === "Declined") {
          navigate("../declined-users");
        }
        dispatch(confirmKycSuccessAction());
      } else {
        dispatch(confirmKycFailureAction(response.data.error_description));
        setShowConfirmDialog(false);
      }
    } catch (error) {
      setShowConfirmDialog(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
        onSubmit: () => {
          hideMessage();
        },
      });
      dispatch(confirmKycFailureAction(error.error_description));
    }
  };
};

export const confirmKycStartedAction = () => ({
  type: CONFIRM_KYC_STARTED,
});

export const confirmKycSuccessAction = (payload) => ({
  type: CONFIRM_KYC_SUCCESS,
  payload,
});

export const confirmKycFailureAction = (message) => ({
  type: CONFIRM_KYC_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const makeStarterUserRequestAction = ({ userId, navigate }) => {
  return async (dispatch) => {
    dispatch(makeStarterUserStartedAction());
    try {
      let response = await makeStarterUserAPI({
        userId,
      });
      if (!response.error) {
        navigate("../starter-users");
        dispatch(makeStarterUserSuccessAction());
      } else {
        dispatch(makeStarterUserFailureAction(response.data.error_description));
        dispatch(getKnowYourCustomerRequestAction({ customerId: userId }));
      }
    } catch (error) {
      showMessage({
        description: error.error_description.message,
        status: "error",
        onSubmit: () => {
          hideMessage();
        },
      });
      dispatch(makeStarterUserFailureAction(error.error_description));
    }
  };
};

export const makeStarterUserStartedAction = () => ({
  type: MAKE_STARTER_USER_STARTED,
});

export const makeStarterUserSuccessAction = (payload) => ({
  type: MAKE_STARTER_USER_SUCCESS,
  payload,
});

export const makeStarterUserFailureAction = (message) => ({
  type: MAKE_STARTER_USER_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const makePremiumUserRequestAction = ({ userId }) => {
  return async (dispatch) => {
    dispatch(makePremiumUserStartedAction());
    try {
      let response = await makePremiumUserAPI({
        userId,
      });
      if (!response.error) {
        dispatch(makePremiumUserSuccessAction());
        dispatch(getKnowYourCustomerRequestAction({ customerId: userId }));
        showMessage({
          description:
            "Email successfully sent to the service provider company.",
          status: "success",
          onSubmit: () => {
            hideMessage();
          },
        });
      } else {
        dispatch(makePremiumUserFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(makePremiumUserFailureAction(error.error_description));
    }
  };
};

export const makePremiumUserStartedAction = () => ({
  type: MAKE_PREMIUM_USER_STARTED,
});

export const makePremiumUserSuccessAction = (payload) => ({
  type: MAKE_PREMIUM_USER_SUCCESS,
  payload,
});

export const makePremiumUserFailureAction = (message) => ({
  type: MAKE_PREMIUM_USER_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getStarterUsersRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getStarterUsersStartedAction());
    try {
      let response = await getStarterUsersAPI({ limit, offset });
      if (!response.error) {
        dispatch(getStarterUsersSuccessAction({ starterUsers: response.data }));
      } else {
        dispatch(getStarterUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getStarterUsersFailureAction(error.error_description));
    }
  };
};

export const getStarterUsersStartedAction = () => ({
  type: GET_STARTER_USERS_STARTED,
});

export const getStarterUsersSuccessAction = (payload) => ({
  type: GET_STARTER_USERS_SUCCESS,
  payload,
});

export const getStarterUsersFailureAction = (message) => ({
  type: GET_STARTER_USERS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getPremiumUsersRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getPremiumUsersStartedAction());
    try {
      let response = await getPremiumUsersAPI({ limit, offset });
      if (!response.error) {
        dispatch(getPremiumUsersSuccessAction({ premiumUsers: response.data }));
      } else {
        dispatch(getPremiumUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getPremiumUsersFailureAction(error.error_description));
    }
  };
};

export const getPremiumUsersStartedAction = () => ({
  type: GET_PREMIUM_USERS_STARTED,
});

export const getPremiumUsersSuccessAction = (payload) => ({
  type: GET_PREMIUM_USERS_SUCCESS,
  payload,
});

export const getPremiumUsersFailureAction = (message) => ({
  type: GET_PREMIUM_USERS_FAILURE,
  payload: message,
});
