import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getVirtualCardsListRequestAction } from "redux/actions/cardActions";
import { getUsersLogRequestAction } from "redux/actions/logActions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomTable from "components/CustomTable";
// --------------------------------------------------------------------------------
const UsersLog = ({ data }) => {
  const dispatch = useDispatch();

  const { usersLog } = useSelector((state) => ({
    usersLog: state.log.usersLog,
  }));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  useEffect(() => {
    dispatch(
      getUsersLogRequestAction({
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "requestUrl",
        header: "Request Url",
      },
      {
        accessorKey: "requestInput",
        header: "Request Input",
        Cell: ({ cell, row }) => (
          <IconButton
            onClick={() =>
              navigator.clipboard.writeText(row.original.requestInput)
            }
          >
            <ContentCopyIcon color="primary" />
          </IconButton>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "responseBody",
        header: "Response Body",
        Cell: ({ cell, row }) => (
          <IconButton
            onClick={() =>
              navigator.clipboard.writeText(row.original.responseBody)
            }
          >
            <ContentCopyIcon color="primary" />
          </IconButton>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Users Log"
      columns={columns}
      data={usersLog.records}
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={usersLog.count}
    />
  );
};

export default UsersLog;
