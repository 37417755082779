import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserLogs } from "redux/actions/usersActions";
import CustomTable from "components/CustomTable";
import withDateRangePicker from "HOC/withDateRangePicker";

function Logs({ userId, from, to, renderTopToolbar }) {
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [data, setData] = useState({ data: [] });

  useEffect(() => {
    fetch();
  }, [userId, pagination.pageIndex, pagination.pageSize, from, to]);

  const fetch = () => {
    dispatch(
      getUserLogs({
        setData,
        userId,
        from,
        to,
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "browser_version",
        header: "Browser Version",
      },
      {
        accessorKey: "browser",
        header: "Browser",
      },
      {
        accessorKey: "device_type",
        header: "Device Type",
      },
      {
        accessorKey: "device_family",
        header: "Device Family",
      },
      {
        accessorKey: "device_model",
        header: "Device Model",
      },
      {
        accessorKey: "platform_version",
        header: "Platform Version",
      },
      {
        accessorKey: "platform_family",
        header: "Platform Family",
      },
      {
        accessorKey: "platform_name",
        header: "Platform Name",
      },
      {
        accessorKey: "ip_address",
        header: "Ip Address",
      },
      {
        accessorKey: "location",
        header: "Location",
      },
      {
        accessorKey: "Lng",
        header: "Lng",
      },
      {
        accessorKey: "Lat",
        header: "Lat",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "timezone",
        header: "Timezone",
      },
      {
        accessorKey: "created_at",
        header: "Created At",
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Logs"
      data={data.data}
      columns={columns}
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={data.count}
      renderTopToolbar={renderTopToolbar}
    />
  );
}

export default withDateRangePicker(Logs);
