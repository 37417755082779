import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AppButton from "components/AppButton";
import AppTextInput from "components/AppTextInput/index";
import { LOGIN_STARTED, VERIFY_STARTED } from "redux/ActionTypes";
import Logo from "../assets/images/logo-dark.svg";
import {
  loginRequestAction,
  verifyRequestAction,
} from "redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rType } = useSelector((state) => state.auth, shallowEqual);

  const [showError, setShowError] = useState(false);
  const [step, setStep] = useState(0);
  const [verify_token, setVerify_token] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (verify_token) {
      dispatch(
        verifyRequestAction({ email, password, verifyCode: verify_token })
      ).then(() => {
        // Redirect to dashboard if verification is successful
        navigate("/dashboard");
      });
    } else {
      dispatch(loginRequestAction({ email, password, setStep }));
    }
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-login-content">
          <div className="app-login-header mb-4 text-center">
            <img
              src={Logo}
              alt="Yekpay"
              title="Yekpay"
              className="app-login-header mb-4"
            />
            <h3>Login To Administration Area</h3>
          </div>
          <form>
            <div className="app-login-form">
              <AppTextInput
                label="Email"
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                customClass="my-3"
              />
              <AppTextInput
                type="password"
                label="Password"
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                customClass="my-3"
              />
              {step === 1 && (
                <AppTextInput
                  label="Token"
                  fullWidth
                  onChange={(event) => setVerify_token(event.target.value)}
                  value={verify_token}
                  autoFocus
                  customClass="my-3"
                />
              )}
              {/* <Link
                component="button"
                type="button"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password
              </Link> */}
              <div className="mb-3 mt-3 d-flex align-items-center justify-content-between">
                <AppButton
                  text="Sign in"
                  type="submit"
                  onClick={handleFormSubmit}
                  variant="contained"
                  color="primary"
                  loading={rType === LOGIN_STARTED || rType === VERIFY_STARTED}
                  disabled={!email || !password}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
