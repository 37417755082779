import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Link } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getVirtualCardsListRequestAction } from "redux/actions/cardActions";
import {
  getPepLogsRequestAction,
  getUsersLogRequestAction,
} from "redux/actions/logActions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomTable from "components/CustomTable";
// --------------------------------------------------------------------------------
const PepLogs = ({ data }) => {
  const dispatch = useDispatch();

  const { pepLogs } = useSelector((state) => ({
    pepLogs: state.log.pepLogs,
  }));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  useEffect(() => {
    dispatch(
      getPepLogsRequestAction({
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "url",
        header: "Url",
      },
      {
        accessorKey: "type",
        header: "Type",
      },
      {
        accessorKey: "errorCode",
        header: "Error Code",
      },
      {
        accessorKey: "requestBody",
        header: "Request Body",
        Cell: ({ cell, row }) => (
          <IconButton
            onClick={() =>
              navigator.clipboard.writeText(row.original.requestBody)
            }
          >
            <ContentCopyIcon color="primary" />
          </IconButton>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "responseBody",
        header: "Response Body",
        Cell: ({ cell, row }) => (
          <IconButton
            onClick={() =>
              navigator.clipboard.writeText(row.original.responseBody)
            }
          >
            <ContentCopyIcon color="primary" />
          </IconButton>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Pep Logs"
      columns={columns}
      data={pepLogs.records}
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={pepLogs.count}
    />
  );
};

export default PepLogs;
