import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserTransactions } from "redux/actions/usersActions";
import { camelCaseToTitleCase } from "constants/usefulFunctions";
import CustomTable from "components/CustomTable";
import withDateRangePicker from "HOC/withDateRangePicker";

function Transactions({ userId, renderTopToolbar, from, to }) {
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [data, setData] = useState({ records: [] });

  useEffect(() => {
    fetch();
  }, [pagination.pageIndex, pagination.pageSize, from, to]);

  const fetch = () => {
    dispatch(
      getUserTransactions({
        setData,
        userId,
        from,
        to,
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date",
      },
      {
        accessorKey: "event",
        header: "Event",
        Cell: ({ cell, row }) => camelCaseToTitleCase(row.original.event),
      },
      {
        accessorKey: "amount",
        header: "Amount",
      },
      {
        accessorKey: "currency",
        header: "Currency",
      },
      {
        accessorKey: "beneficiary",
        header: "Beneficiary",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "referenceNumber",
        header: "Reference Number",
      },
      {
        accessorKey: "bankReferenceNumber",
        header: "Bank Reference Number",
      },
      {
        accessorKey: "receipt",
        header: "Receipt",
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Transactions"
      columns={columns}
      data={data.records}
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={data.count}
      renderTopToolbar={renderTopToolbar}
    />
  );
}

export default withDateRangePicker(Transactions);
