import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAdminInfo } from "redux/actions/adminActions";
import Auxiliary from "util/Auxiliary";
import AdminProfileHeader from "../ProfileHeader";
import { AppBar } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PaidIcon from "@mui/icons-material/Paid";
import GroupsIcon from "@mui/icons-material/Groups";
import PropTypes from "prop-types";
import AdminFees from "./AdminFees";
import AdminRoles from "./AdminRoles";
import WalletIcon from "@mui/icons-material/Wallet";
import AdminBalance from "./AdminBalance";

const TabContainer = ({ children }) => {
  return <div className="pt-3">{children}</div>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AdminProfile = () => {

    const dispatch = useDispatch();
    const {id:adminId , section} = useParams();
    const history = useNavigate();

    const currentPath = `/app/adminProfile/${adminId}`;
    
    const [admin , setAdmin] = useState({});

    useEffect(() => {
       if(adminId) fetchAdminData(adminId)
    },[adminId]);

    const fetchAdminData = async adminId => {
       dispatch(getAdminInfo({setAdmin , adminId}));
    }

    const activeTab = {
      Fees: 0,
      Roles : 1,
      Balance : 2
    };

    return (
      <Auxiliary>
        <AdminProfileHeader />
        <div className="jr-profile-content">
          <div className="w-100">
            <AppBar position="static" color="inherit">
              <Tabs
                value={activeTab[section]}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                allowScrollButtonsMobile={true}
                textColor="primary"
              >
                <Tab
                  label="Fees and Limit"
                  icon={<PaidIcon />}
                  onClick={() => history(`${currentPath}/Fees`)}
                />
                <Tab
                  label="Roles"
                  icon={<GroupsIcon />}
                  onClick={() => history(`${currentPath}/Roles`)}
                />
                <Tab
                  label="Wallets"
                  icon={<WalletIcon />}
                  onClick={() => history(`${currentPath}/Balance`)}
                />
              </Tabs>
            </AppBar>
            {section === "Fees" && (
              <TabContainer>
                <AdminFees adminId={adminId} />
              </TabContainer>
            )}
            {section === "Roles" && (
              <TabContainer>
                <AdminRoles />
              </TabContainer>
            )}
            ,
            {section === "Balance" && (
              <TabContainer>
                <AdminBalance adminId={adminId} />
              </TabContainer>
            )}
          </div>
        </div>
      </Auxiliary>
    );
}

export default AdminProfile;