import React from "react";
import Widget from "components/Widget/index";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";

const DataCard = (props) => {
  const {
    imageIcon,
    title,
    subTitle,
    background = "primary",
    loading,
    onClick,
    customClassName,
  } = props;
  return (
    <div
      onClick={onClick}
      className={classNames(customClassName ?? "col-lg-4 col-sm-6 pointer")}
    >
      <Widget styleName={`p-4 bg-${background}`}>
        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress style={{ color: "white" }} size={30} />
          </div>
        ) : (
          <div className="media align-items-center flex-nowrap text-white">
            {imageIcon && (
              <div className="mr-lg-4 mr-3">
                <img
                  className="img-fluid"
                  style={{ width: "30px" }}
                  src={imageIcon}
                  alt="..."
                />
              </div>
            )}
            <div className="media-body">
              <div className="row">
                <h1 className="jr-font-weight-bold mb-0 mr-2">{title}</h1>
                <p className="text-white mb-0">{subTitle}</p>
              </div>
            </div>
          </div>
        )}
      </Widget>
    </div>
  );
};

export default DataCard;
