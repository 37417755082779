import axiosMiddleware from "./axiosMiddleware";

export const downloadFileAPI = ({ fileAddress }) => {
  let postData = {
    file: fileAddress,
  };
  return axiosMiddleware({
    method: "post",
    url: "document-download",
    data: postData,
  });
};
export const uploadFileAPI = (formData, uploadPercent) => {
  let postData = formData;
  return axiosMiddleware({
    method: "post",
    url: "document-upload",
    timeout: 60000,
    data: postData,
    trackUploadProgress: (percent) => {
      uploadPercent(percent);
    },
  });
};
