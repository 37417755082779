import {
  DELETE_DEFAULT_WALLET_FAILURE,
  DELETE_DEFAULT_WALLET_STARTED,
  DELETE_DEFAULT_WALLET_SUCCESS,
  GET_AGENTS_LIST_FAILURE,
  GET_AGENTS_LIST_STARTED,
  GET_AGENTS_LIST_SUCCESS,
} from "redux/ActionTypes";
import {
  deleteDefaultWalletAPI,
  editAgentDefaultWalletAPI,
  getAgentsListAPI,
} from "services/API/agent";
import { getUserDefaultsRequestAction } from "./usersActions";
// --------------------------------------------------------------------------------

export const getAgentsListStartedAction = () => ({
  type: GET_AGENTS_LIST_STARTED,
});

export const getAgentsListSuccessAction = (payload) => ({
  type: GET_AGENTS_LIST_SUCCESS,
  payload,
});

export const getAgentsListFailureAction = (message) => ({
  type: GET_AGENTS_LIST_FAILURE,
  payload: message,
});

export const getAgentsListRequestAction = () => {
  return async (dispatch) => {
  
    dispatch(getAgentsListStartedAction());
    try {
      let response = await getAgentsListAPI();
      if (!response.error) {
        dispatch(getAgentsListSuccessAction({ agentsList: response.data }));
      } else {
        dispatch(getAgentsListFailureAction(response.data.error_description));
      }
    } catch (error) {
      console.log(error);
      dispatch(getAgentsListFailureAction(error.error_description));
    }
  };
};


// --------------------------------------------------------------------------------
export const deleteDefaultWalletRequestAction = ({ id }) => {
  return async (dispatch) => {
    dispatch(deleteDefaultWalletStartedAction());
    try {
      let response = await deleteDefaultWalletAPI({ id });
      if (!response.error) {
        dispatch(deleteDefaultWalletSuccessAction());
        dispatch(getUserDefaultsRequestAction());
      } else {
        dispatch(
          deleteDefaultWalletFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(deleteDefaultWalletFailureAction(error.error_description));
    }
  };
};

export const deleteDefaultWalletStartedAction = () => ({
  type: DELETE_DEFAULT_WALLET_STARTED,
});

export const deleteDefaultWalletSuccessAction = (payload) => ({
  type: DELETE_DEFAULT_WALLET_SUCCESS,
  payload,
});

export const deleteDefaultWalletFailureAction = (message) => ({
  type: DELETE_DEFAULT_WALLET_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const editAgentDefaultWalletRequestAction = ({ id, currencies }) => {
  return async (dispatch) => {
    dispatch(editAgentDefaultWalletStartedAction());
    try {
      let response = await editAgentDefaultWalletAPI({ id, currencies });
      if (!response.error) {
        dispatch(editAgentDefaultWalletSuccessAction());
      } else {
        dispatch(
          editAgentDefaultWalletFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(editAgentDefaultWalletFailureAction(error.error_description));
    }
  };
};

export const editAgentDefaultWalletStartedAction = () => ({
  type: DELETE_DEFAULT_WALLET_STARTED,
});

export const editAgentDefaultWalletSuccessAction = (payload) => ({
  type: DELETE_DEFAULT_WALLET_SUCCESS,
  payload,
});

export const editAgentDefaultWalletFailureAction = (message) => ({
  type: DELETE_DEFAULT_WALLET_FAILURE,
  payload: message,
});
