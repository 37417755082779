import { useEffect, useState } from "react";
import MuiCardMedia from "@mui/material/CardMedia";
import {
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { downloadFileAPI } from "services/API/fileManager";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useParams } from "react-router-dom";
import { changeStatusKycFieldRequestAction } from "redux/actions/customerActions";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import FileSaver from "file-saver";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

let PDFJS = window.pdfjsLib;

const CardMedia = ({ fileAddress: item }) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [isDeclined, setIsDeclined] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editable, setEditable] = useState(true);

  const { id: customerId } = useParams();

  useEffect(() => {
    item && showPdf();
  }, [item]);

  const download = () => {
    let url = pdf || images;
    FileSaver.saveAs(url, "document");
  };

  async function showPdf(event) {
    // setLoading(true);
    try {
      let response = await downloadFileAPI({ fileAddress: item?.value });
      let base64Data = `data:${response.data.type};base64,${response.data.value}`;
      if (response.status === "success") {
        if (response.data.type === "application/pdf") {
          let _PDF_DOC = await PDFJS.getDocument(base64Data);
          renderPage(_PDF_DOC);
        } else {
          setImages(base64Data);
        }
        // renderPage(_PDF_DOC);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    } catch (error) {
      //   setLoading(false);
      console.log(error.message);
    }
  }

  async function renderPage(pdf) {
    const canvas = document.createElement("canvas");

    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    console.log("page lenght", pdf.numPages);

    await page.render(render_context).promise;
    let img = canvas.toDataURL("image/png");
    setImages(img);
  }

  return (
    <Card elevation={0}>
      <CardHeader
        title={item.label}
        titleTypographyProps={{ sx: { textAlign: "center" } }}
      />
      <MuiCardMedia
        image={images}
        sx={{ height: 400, backgroundSize: "contain" }}
      />
      <CardActions sx={{ justifyContent: "center" }}>
        {item?.status !== "Initial" && !edit && editable ? (
          <IconButton color="primary" onClick={() => setEdit(true)}>
            <EditIcon />
          </IconButton>
        ) : editable ? (
          <>
            <IconButton
              onClick={() =>
                dispatch(
                  changeStatusKycFieldRequestAction({
                    id: item?.id,
                    status: "Declined",
                    customerId,
                    setIsDeclined,
                    setEdit,
                    setLoading: setDeclineLoading,
                  })
                )
              }
            >
              {declineLoading ? (
                <CircularProgress size={21} color="error" />
              ) : (
                <CancelOutlinedIcon color="error" />
              )}
            </IconButton>
            <IconButton
              color="success"
              onClick={() =>
                dispatch(
                  changeStatusKycFieldRequestAction({
                    id: item?.id,
                    status: "Approved",
                    customerId,
                    setIsDeclined,
                    setEdit,
                    setLoading: setApproveLoading,
                  })
                )
              }
            >
              {approveLoading ? (
                <CircularProgress size={21} color="success" />
              ) : (
                <CheckCircleOutlineIcon />
              )}
            </IconButton>
          </>
        ) : (
          <></>
        )}
        {!edit &&
          (item?.status === "Approved" ? (
            <CheckCircleIcon color="success" />
          ) : item?.status === "Declined" ? (
            <CancelIcon color="error" />
          ) : item?.status === "Pending" ? (
            <ErrorIcon color="warning" />
          ) : null)}
        <IconButton color="primary" onClick={download}>
          <FileDownloadOutlinedIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default CardMedia;
