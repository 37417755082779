import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmKycRequestAction,
  getKnowYourCustomerRequestAction,
  makePremiumUserRequestAction,
  makeStarterUserRequestAction,
} from "redux/actions/customerActions";
import {
  CONFIRM_KYC_STARTED,
  MAKE_PREMIUM_USER_STARTED,
  MAKE_STARTER_USER_STARTED,
} from "redux/ActionTypes";
import KycSection from "./kycSection";
import CardMedia from "./Document";

const UserKyc = () => {
  const { id: customerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permissions = useSelector((state) => state.auth.user.permissions);

  const {
    kycFields,
    rType,
    pepAccountStatus,
    userStatus,
    email,
    mobile,
    premiumRequest,
  } = useSelector((state) => state.customer);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  let isButtonDisable =
    kycFields.length === 0
      ? true
      : Object.values(kycFields).some((item) =>
          item.fields.some(
            (field) => field.status === "Initial" || field.status === "Pending"
          )
        );

  useEffect(() => {
    dispatch(getKnowYourCustomerRequestAction({ customerId }));
  }, []);

  // useEffect(() => {
  //   if (kycFields?.length) {
  //     setShowSubmit(true);
  //     checkValidateToSubmit();
  //   }
  // }, [kycFields]);

  // const checkValidateToSubmit = () => {
  //   kycFields.forEach((item) => {
  //     item.fields.forEach((field) => {
  //       if (field.status === "Initial") {
  //         setShowSubmit(false);
  //       }
  //     });
  //   });
  // };
  const renderConfirmDialog = (
    <Dialog
      open={showConfirmDialog}
      onClose={() => setShowConfirmDialog(false)}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogTitle>Are you sure?</DialogTitle>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setShowConfirmDialog(false)}
          fullWidth
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => {
            dispatch(
              confirmKycRequestAction({
                userId: customerId,
                navigate,
                setShowConfirmDialog,
              })
            );
          }}
          loading={rType === CONFIRM_KYC_STARTED}
          fullWidth
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                Email: <Typography sx={{ fontWeight: 500 }}>{email}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                Mobile:{" "}
                <Typography sx={{ fontWeight: 500 }}>{mobile}</Typography>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mb: 2 }}>
        <CardHeader title="Personal" />
        <KycSection
          item={{
            fields: kycFields?.personal?.fields,
            documentFile: kycFields?.documents?.fields?.find(
              (field) => field.name === "selfie"
            ),
          }}
          userStatus={userStatus}
        />
      </Card>
      <Card sx={{ mb: 2 }}>
        <CardHeader title="Nationality" />
        <KycSection
          item={{
            fields: kycFields?.nationality?.fields,
            documentFile: kycFields?.documents?.fields?.find(
              (field) => field.name === "identity_proof"
            ),
          }}
          userStatus={userStatus}
        />
      </Card>
      <Card sx={{ mb: 2 }}>
        <CardHeader title="Address" />
        <KycSection
          item={{
            fields: kycFields?.address?.fields,
            documentFile: kycFields?.documents?.fields?.find(
              (field) => field.name === "address_proof"
            ),
          }}
          userStatus={userStatus}
        />
      </Card>
      {kycFields.documents?.fields?.length > 0 && (
        <Card sx={{ mb: 2 }}>
          <CardHeader title="Documents" />
          <CardContent>
            <Grid container spacing={3}>
              {kycFields.documents?.fields?.find(
                (field) => field.name === "passport_proof"
              ) && (
                <Grid item xs={12} lg={6}>
                  <CardMedia
                    fileAddress={kycFields.documents?.fields?.find(
                      (field) => field.name === "passport_proof"
                    )}
                  />
                </Grid>
              )}
              {kycFields.documents?.fields?.find(
                (field) => field.name === "card_delivery_proof"
              ) && (
                <Grid item xs={12} lg={6}>
                  <CardMedia
                    fileAddress={kycFields.documents?.fields?.find(
                      (field) => field.name === "card_delivery_proof"
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
      
      {userStatus !== "Declined" && (
        <Card>
          <CardActions sx={{ justifyContent: "center" }}>
            {pepAccountStatus === null && permissions.some((p) => p == 'Make Users Confirm') ? (
              <LoadingButton
                variant="contained"
               // disabled={isButtonDisable}
                onClick={() => setShowConfirmDialog(true)}
              >
                Submit
              </LoadingButton>
            ) : null}
            {pepAccountStatus === null && permissions.some((p) => p == 'See Approved Users')
             && permissions.some((p) => p == 'Make Users Starter')
             && userStatus === "Approved" ? (
              <LoadingButton
                variant="contained"
                color="success"
                onClick={() =>
                  dispatch(
                    makeStarterUserRequestAction({
                      userId: customerId,
                      navigate,
                    })
                  )
                }
                loading={rType === MAKE_STARTER_USER_STARTED}
              >
                Make it starter
              </LoadingButton>
            ) : pepAccountStatus === "starter" && userStatus === "Approved" && permissions.some((p) => p == 'Make Users Premium') ? (
              <LoadingButton
                variant="contained"
                color="warning"
                onClick={() =>
                  dispatch(makePremiumUserRequestAction({ userId: customerId }))
                }
                loading={rType === MAKE_PREMIUM_USER_STARTED}
                disabled={premiumRequest}
              >
                Make it premium
              </LoadingButton>
            ) : null}
          </CardActions>
        </Card>
      )}
      {renderConfirmDialog}
    </>
  );
};

export default UserKyc;
