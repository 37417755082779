import React, { useEffect, useMemo, useState } from "react";
import { boot, CurrencyFlags } from "constants/constants";
import { useDispatch } from "react-redux";
import {
  getUserTransactions,
  getUserVirtualCards,
} from "redux/actions/usersActions";
import { Link } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import CustomTable from "components/CustomTable";

const VirtualCards = ({ userId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    // if (lookup) fetchData();
    if (userId) dispatch(getUserVirtualCards({ setData, userId }));
  }, [userId]);

  const columns = useMemo(
    () => [
      {
        header: "Pep Card ID",
        accessorKey: "cardId",
        Cell: ({ cell, row }) => (
          <Link target="_blank" to={`/cards/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "Masked Card Number",
        accessorKey: "cardNumber",
      },
      {
        header: "Brand",
        accessorKey: "brand",
      },
      {
        header: "Card Name",
        accessorKey: "name",
      },
      {
        header: "Status",
        accessorKey: "status",
      },
      {
        header: "Created Time",
        accessorKey: "createdTime",
      },
    ],
    []
  );

  return <CustomTable columns={columns} data={data} manualPagination={false} />;
};

export default VirtualCards;
