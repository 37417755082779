import React, { Component } from "react";
// import { View, Text } from "react-native";
// import {
//   greenColor,
//   redColor,
//   accentText,
//   blackColor,
// } from "../../assets/colors";
import ModalManager from "./ModalManager";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "components/Button";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import Typography from "@mui/material/Typography";
import { Margin } from "@mui/icons-material";

export default class Modal extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      style: {},
      message: null,
      color: "red",
      // duration: 3000,
      loading: false,
    };
    this.showMessage = this.showMessage.bind(this);
    this.hideMessage = this.hideMessage.bind(this);
    this.showCalled = true;
    if (!this._id) this._id = srid();
  }

  componentDidMount() {
    ModalManager.register(this);
  }
  componentWillUnmount() {
    ModalManager.unregister(this);
  }

  showMessage(config) {
    this.showCalled = true;
    console.log("setState");
    this.setState({ open: true, ...config });
  }

  hideMessage() {
    this.setState({
      open: false,
      message: null,
      color: "red",
      // duration: 3000,
    });
  }
  renderIcon = (status) => {
    let icon = null;
    switch (status) {
      case "success":
        icon = <TaskAltIcon sx={{ color: "#FFFFFF", width: 40, height: 40 }} />;
        break;
      case "error":
        icon = (
          <ErrorOutlineIcon sx={{ color: "#FFFFFF", width: 40, height: 40 }} />
        );
        break;
      case "warning":
        icon = (
          <WarningAmberIcon sx={{ color: "#FFFFFF", width: 40, height: 40 }} />
        );
        break;
      default:
        break;
    }
    return icon;
  };

  renderColor = (status) => {
    let color = "";
    switch (status) {
      case "success":
        color = "#2e7d32";
        break;
      case "error":
        color = "#d32f2f";
        break;
      case "warning":
        color = "#ed6c02";
        break;
      default:
        break;
    }
    return color;
  };
  render() {
    let {
      open,
      title,
      description = "Done Successfully.",
      onSubmit,
      loading,
      style,
      message,
      status = "success",
      color,
      duration,
      className,
      confirmBtnText = "OK",
      cancelBtnText,
      onCancel,
    } = this.state;

    if (duration && typeof duration == "number" && this.showCalled) {
      setTimeout(() => {
        this.showCalled = false;
        this.hideMessage();
      }, duration);
    }

    return (
      <Dialog
        open={open}
        onClose={() => this.setState({ open: false })}
        sx={{
          ".MuiDialogContent-root": {
            padding: 0,
          },
        }}
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <Box
            sx={{
              padding: 1,
              backgroundColor: this.renderColor(status),
            }}
          >
            {this.renderIcon(status)}
          </Box>
          <Box sx={{ pt: 3, pb: 2 }}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box sx={{ px: 3, mb: 2, display: description ? "block" : "none" }}>
            <Typography variant="p">{description}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={6}>
          {status === "warning" && (
            <Button color={status} variant="outlined" onClick={onCancel}>
              {cancelBtnText}
            </Button>
          )}
          <Button
            color={status}
            text={confirmBtnText}
            onClick={() =>
              onSubmit((loading) => this.setState({ ...this.state, loading }))
            }
            style={{ maxWidth: 200, margin: "auto", width: "fit-content" }}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export function showMessage(...args) {
  const ref = ModalManager.getDefault();
  if (!!ref) {
    ref.showMessage(...args);
  }
}

export function hideMessage(...args) {
  const ref = ModalManager.getDefault();
  if (!!ref) {
    ref.hideMessage();
  }
}

function srid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4()}-${s4()}-${s4()}`;
}

const styles = {
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    zIndex: 1000,
  },
  text: {
    fontFamily: "IRANYekanMobileFaNum",
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "right",
    writingDirection: "rtl",
    lineHeight: 25,
    paddingHorizontal: 20,
  },
};
