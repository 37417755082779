import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getUsersAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "/users/list",
  });
};
export const getCurrenciesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "currencies",
  });
};
export const createUserDefaultWalletAPI = ({ currencies, agentId }) => {
  let postData = {
    currencies,
    agentId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/users/default/add",
    data: postData,
  });
};
export const getUserDefaultsAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/users/default/get",
  });
};
export const getUserFeesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/users/defaultFee/get",
  });
};
export const setUserFeesAPI = (data) => {
  return axiosMiddleware({
    method: "post",
    url: "/user/set-fee",
    data,
  });
};
export const setAdminFeesAPI = (data) => {
  return axiosMiddleware({
    method: "post",
    url: "/admin/set-fee",
    data,
  });
};
export const createUserFeesAPI = (postData) => {
  return axiosMiddleware({
    method: "post",
    url: "/users/defaultFee/add",
    data: postData,
  });
};
export const deleteUserFeesAPI = (postData) => {
  return axiosMiddleware({
    method: "delete",
    url: "/users/defaultFee/delete",
    data: postData,
  });
};
export const getUsersBalancesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/users/balance",
  });
};
export const getPepBalancesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/pep/balance",
  });
};
export const getUserInfoAPI = ({ userId }) => {
  let postData = {
    userId: userId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/user/info",
    data: postData,
  });
};
export const getUserDetailsAPI = ({ userId }) => {
  let postData = {
    userId: userId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/user/detail",
    data: postData,
  });
};

export const getCardDetailsAPI = ({ cardId }) => {
  let postData = {
    cardId: cardId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/card/detail",
    data: postData,
  });
};
export const getUserWalletAPI = ({ userId }) => {
  let postData = {
    userId: userId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/user/wallet",
    data: postData,
  });
};
export const editWalletMinimumBalanceAPI = ({ walletId, minBalance }) => {
  let postData = {
    walletId,
    minBalance,
  };
  return axiosMiddleware({
    method: "post",
    url: "/user/wallet/edit",
    data: postData,
  });
};
export const getUserPhysicalCardAPI = ({ userId }) => {
  let postData = {
    userId: userId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/user/cards/plastic",
    data: postData,
  });
};

export const getUserVirtualCardAPI = ({ userId }) => {
  let postData = {
    userId: userId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/user/cards/virtual",
    data: postData,
  });
};
export const getUserTransactionsAPI = ({ userId, from, to, limit, offset }) => {
  let postData = {
    userId: userId,
    from,
    to,
    limit,
    offset,
  };
  return axiosMiddleware({
    method: "post",
    url: `/user/transactions`,
    data: postData,
  });
};
export const getUserLogsAPI = ({ userId, from, to, limit, offset }) => {
  let postData = {
    userId: userId,
    from,
    to,
    limit,
    offset,
  };
  return axiosMiddleware({
    method: "post",
    url: `/user/agents`,
    data: postData,
  });
};

export const getCardTransactionsAPI = ({ cardId, from, to, limit, offset }) => {
  let postData = {
    cardId,
    from,
    to,
    limit,
    offset,
  };
  return axiosMiddleware({
    method: "post",
    url: `/card/transactions`,
    data: postData,
  });
};
export const getUserDefaultFeeAPI = (data) => {
  return axiosMiddleware({
    method: "post",
    url: "/users/defaultFee/check",
    data,
  });
};
export const getUsersRegistrationAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/users/registration-count",
  });
};
export const getUsersSettingsAPI = (userId) => {
  return axiosMiddleware({
    method: "post",
    url: "/user/get-settings",
    data: {
      userId,
    },
  });
};
export const setUsersSettingsAPI = (settings) => {
  return axiosMiddleware({
    method: "post",
    url: "/user/set-settings",
    data: settings,
  });
};
export const getUserFeesListAPI = (userId) => {
  return axiosMiddleware({
    method: "post",
    url: "/user/fees",
    data: {
      userId,
    },
  });
};
export const getUserDocumentsAPI = (userId) => {
  return axiosMiddleware({
    method: "post",
    url: "/user/documents",
    data: {
      userId,
    },
  });
};
