import React from "react";
import Switch from "@mui/material/Switch";

const AppSwitch = ({ label, checked, onChange }) => {
  return (
    <div className="app-switch">
      <span>{label}</span>
      <Switch
        classes={{
          checked: "text-primary",
        }}
        checked={checked}
        onChange={onChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </div>
  );
};

export default AppSwitch;
