import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_TablePagination,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToolbarAlertBanner,
  useMaterialReactTable,
  // type MRT_ColumnDef,
  MRT_TableContainer,
  MRT_FullScreenToggleButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFullScreenButton,
} from "material-react-table";
import {
  IconButton,
  Box,
  Button,
  Typography,
  Tooltip,
  Paper,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

const CustomTable = ({
  label,
  columns,
  data,
  onPaginationChange,
  state,
  rowCount,
  manualPagination = true,
  enableRowActions,
  renderRowActions,
  renderTopToolbar,
  enableEditing,
  onEditingRowSave,
}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    // enableRowSelection: true,
    // initialState: { showGlobalFilter: true },
    enableRowNumbers: true,
    ...(onPaginationChange && { onPaginationChange }),
    state,
    rowCount,
    manualPagination,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { maxHeight: "500px" } },
    // enableFullScreenToggle: true,
    enableRowActions,
    renderRowActions,
    positionActionsColumn: "last",
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing,
    onEditingRowSave,
  });

  return (
    <Paper square>
      {/* Our Custom External Top Toolbar */}
      <Box
        sx={(theme) => ({
          display: "flex",
          backgroundColor: "inherit",
          borderRadius: "4px",
          flexDirection: "row",
          gap: "16px",
          justifyContent: "space-between",
          padding: "24px 16px",
          alignItems: "center",
          flexWrap: "wrap",
          "@media max-width: 768px": {
            flexDirection: "column",
          },
        })}
      >
        <Box>
          <Typography variant="h5">{label}</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          {/* <Tooltip title="Print">
            <IconButton onClick={() => window.print()}>
              <PrintIcon />
            </IconButton>
          </Tooltip> */}
          <MRT_ToggleFullScreenButton table={table} />
        </Box>
      </Box>
      {/* Some Page Content */}
      {renderTopToolbar && renderTopToolbar()}
      {/* The MRT Table with no toolbars built-in */}
      <MRT_TableContainer table={table} />
      {/* Our Custom Bottom Toolbar */}
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <MRT_TablePagination table={table} />
        </Box>
        <Box sx={{ display: "grid", width: "100%" }}>
          <MRT_ToolbarAlertBanner stackAlertBanner table={table} />
        </Box>
      </Box>
    </Paper>
  );
};

export default CustomTable;
