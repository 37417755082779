import React from "react";
import { Container, Typography, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <Container
      className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3"
      maxWidth="sm"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <div className="error-code mb-4 animated zoomInDown">404</div>
        </Grid>
        <Grid item>
          <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
            {/* <IntlMessages id="extraPages.404Msg"/> */}
          </h2>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            className="error-code mb-4 animated zoomInDown"
            sx={{ lineHeight: 2 }}
            align="center"
          >
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
          >
            Go to Home Page
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Error404;
