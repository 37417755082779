import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  getCurrenciesRequestAction,
  getUsersRequestAction,
  topUpRequestAction,
} from "redux/actions/usersActions";
import { useDispatch } from "react-redux";
import Autocomplete from "components/Autocomplete";
import DropDown from "components/DropDown";
import { createExchangeRateAction } from "redux/actions/exchange";
import { hideMessage, showMessage } from "components/Modal";
import AppNumberInput from "components/AppNumberInput";
import { LoadingButton } from "@mui/lab";
// --------------------------------------------------------------------------------
const CreateExchageRate = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currencies, setCurrencies] = useState([]);
  const [rate, setRate] = useState("");
  const [sourceCurrency, setSourceCurrency] = useState("");
  const [targetCurrency, setTargetCurrency] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // dispatch(getUsersRequestAction({ setUsers }));
    dispatch(getCurrenciesRequestAction({ setCurrencies }));
  }, []);

  return (
    <Card>
      <CardHeader title="Create Exchange Rate" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <DropDown
              label="Source Currency"
              schema={{ label: "code", value: "id" }}
              items={currencies.filter(
                (currency) => currency.id !== targetCurrency
              )}
              onChange={(event) => setSourceCurrency(event.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <DropDown
              label="Target Currency"
              schema={{ label: "code", value: "id" }}
              items={currencies.filter(
                (currency) => currency.id !== sourceCurrency
              )}
              onChange={(event) => setTargetCurrency(event.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <AppNumberInput
              thousandSeparator={true}
              label="Rate"
              onChange={(e) => setRate(e.target.value)}
              value={rate}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            dispatch(
              createExchangeRateAction({
                sourceCurrencyId: sourceCurrency,
                targetCurrencyId: targetCurrency,
                rate,
                setLoading,
                onSuccess: () =>
                  showMessage({
                    onSubmit: () => {
                      hideMessage();
                      navigate("/exchange/rate-list");
                    },
                  }),
              })
            );
          }}
        >
          Submit
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default CreateExchageRate;
