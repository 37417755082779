import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "components/CustomTable";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
// --------------------------------------------------------------------------------
const AgentList = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { agentsList } = useSelector((state) => ({
    agentsList: state.agent.agentsList,
  }));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 150,
        Cell: ({row}) => {

          return (row.original.name) 
                   
        }
      },
      {
        accessorKey: "referralCode",
        header: "Referral Code",
        size: 200,
         Cell: ({row}) => {

          return (row.original.topup_commission) 
                   
        }
      },
      {
        accessorKey: "topupCommission",
        header: "Topup Commission",
        size: 200,
        Cell: ({row}) => {

          return (row.original.referral_code) 
                   
        }
      },
      {
        accessorKey: "issueCommission",
        header: "Issue Commission",
        size: 200,
        Cell: ({row}) => {
          return (row.original.issue_commission)   
        }
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Agent List"
      data={agentsList}
      columns={columns}
      manualPagination={false}
    />
  );
};

export default AgentList;
