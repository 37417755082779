import React, { useState, useEffect } from "react";
import ProfileHeader from "./ProfileHeader";
import Summary from "./Summary/index";
import Itsaaz from "./Itsaaz";
import UserLog from "./UserLog/index";
import PhysicalCards from "./PhysicalCards/index";
import VirtualCards from "./VirtualCards/index";
import Transactions from "./Transactions";
import UserStatus from "./UserStatus/index";
import BankAccounts from "./BankAccounts/index";
import Gateways from "./Gateways/index";
import YekpayLinks from "./YekpayLinks/index";
import Tickets from "./Tickets";
import UserBalance from "./UserBalance";
import Report from "./Report";
import TextMessage from "./TextMessage";
import UserPlans from "./UserPlans";
import AdminComments from "./AdminComments";
import Logs from "./Logs";
import Auxiliary from "util/Auxiliary";
import Company from "./Company";
import Billing from "./Billing";
import TransactionsFailed from "./TransactionsFailed";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import HelpIcon from "@mui/icons-material/Help";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ClearAll from "@mui/icons-material/ClearAll";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import AccountBalance from "@mui/icons-material/AccountBalance";
import Launch from "@mui/icons-material/Launch";
import Message from "@mui/icons-material/Message";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import WalletIcon from "@mui/icons-material/Wallet";
import PaidIcon from "@mui/icons-material/Paid";
import TrendingUp from "@mui/icons-material/TrendingUp";
import AssignmentLate from "@mui/icons-material/AssignmentLate";
import Feedback from "@mui/icons-material/Feedback";
import RequestPayout from "./RequestPayout";
import { useParams } from "react-router-dom";
import AssessmentIcon from "@mui/icons-material/Assessment";
import APIs from "services/API/Main";
import useAPI from "services/hook";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import { useNavigate } from "react-router-dom/dist/index";
import { useDispatch } from "react-redux";
import { getPepBalancesAction, getUserInfo } from "redux/actions/usersActions";
import { Grid } from "@mui/material";
import UserSettings from "./UserSettings";
import UserFees from "./UserFees";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import Documents from "./Documents";

const TabContainer = ({ children }) => {
  return <div className="pt-3">{children}</div>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const Profile = () => {
  const dispatch = useDispatch();
  const { id: userId, section } = useParams();
  const history = useNavigate();

  const currentPath = `/app/profile/${userId}`;

  const [user, setUser] = useState({});
  const [verifyByItsaz, setVerifyByItsaz] = useState(false);

  useEffect(() => {
    if (userId) fetchUserData({ userId });
  }, [userId]);

  const { request } = useAPI(APIs.getUserData);

  const fetchUserData = async ({ userId }) => {
    dispatch(getUserInfo({ setUser, userId }));

    // const data = await request({ userId });
    // document.title =
    //   "Profile | " + user.firstName + " " + user.lastName;
    // setVerifyByItsaz(data?.itsaz);
  };

  const activeTab = {
    Summary: 0,
    Documents: 1,
    Balance: 2,
    Transactions: 3,
    PhysicalCards: 4,
    VirtualCards: 5,
    Settings: 6,
    Logs: 7,
    Fees: 8,
    Status: -1,
    Report: -3,
    Tickets: -8,
    TextMessage: -10,
    UserLog: -11,
  };
  return (
    <Auxiliary>
      <ProfileHeader />
      <div className="jr-profile-content">
        <div className="w-100">
          <AppBar position="static" color="inherit">
            <Tabs
              value={activeTab[section]}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              allowScrollButtonsMobile={true}
              textColor="primary"
            >
              <Tab
                label="Summary"
                icon={<PersonPinIcon />}
                onClick={() => history(`${currentPath}/Summary`)}
              />
              <Tab
                label="Documents"
                icon={<DocumentScannerOutlinedIcon />}
                onClick={() => history(`${currentPath}/Documents`)}
              />
              <Tab
                label="Wallets"
                icon={<WalletIcon />}
                onClick={() => history(`${currentPath}/Balance`)}
              />
              {/* <Tab
                label="Report"
                icon={<AssessmentIcon />}
                onClick={() => history(`${currentPath}/Report`)}
              /> */}

              <Tab
                label="Transactions"
                icon={<SwapHoriz />}
                onClick={() => history(`${currentPath}/Transactions`)}
              />

              <Tab
                label="Physical Cards"
                icon={<CreditCardIcon />}
                onClick={() => history(`${currentPath}/PhysicalCards`)}
              />

              <Tab
                label="Virtual Cards"
                icon={<CreditCardIcon />}
                onClick={() => history(`${currentPath}/VirtualCards`)}
              />

              <Tab
                label="Settings"
                icon={<SettingsSuggestIcon />}
                onClick={() => history(`${currentPath}/Settings`)}
              />

              {/* <Tab
                label="Tickets"
                icon={<Message />}
                onClick={() =>
                  history({
                    pathname: `${currentPath}/Tickets`,
                    state: { email: user?.email },
                  })
                }
              /> */}
              <Tab
                label="Logs"
                icon={<Feedback />}
                onClick={() => history(`${currentPath}/Logs`)}
              />

              {/* <Tab
                label="Text Message"
                icon={<Message />}
                onClick={() =>
                  history({
                    pathname: `${currentPath}/TextMessage`,
                    state: { mobile: user?.mobile },
                  })
                }
              />
              <Tab
                label="User Log"
                icon={<PersonPinIcon />}
                onClick={() => history(`${currentPath}/UserLog`)}
              /> */}
              <Tab
                label="Fees and Limit"
                icon={<PaidIcon />}
                onClick={() => history(`${currentPath}/Fees`)}
              />
            </Tabs>
          </AppBar>
          {section === "Summary" && (
            <TabContainer>
              <Summary />
            </TabContainer>
          )}
          {section === "Documents" && (
            <TabContainer>
              <Documents />
            </TabContainer>
          )}

          {section === "Status" && (
            <TabContainer>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <UserStatus
                    refreshUserData={fetchUserData}
                    basicInfo={user}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <AdminComments userId={userId} basicInfo={user} />
                </div>
              </div>
            </TabContainer>
          )}
          {section === "Balance" && (
            <TabContainer>
              <UserBalance userId={userId} />
            </TabContainer>
          )}
          {section === "Report" && (
            <TabContainer>
              <Report userId={userId} />
            </TabContainer>
          )}

          {section === "Transactions" && (
            <TabContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Transactions userId={userId} />
                </Grid>
                {/* <Grid item xs={12}>
                  <TransactionsFailed userId={userId} />
                </Grid> */}
              </Grid>
            </TabContainer>
          )}

          {section === "Tickets" && (
            <TabContainer>
              <Tickets userId={userId} />
            </TabContainer>
          )}
          {section === "Logs" && (
            <TabContainer>
              <Logs userId={userId} />
            </TabContainer>
          )}

          {section === "TextMessage" && (
            <TabContainer>
              <TextMessage userId={userId} />
            </TabContainer>
          )}
          {section === "UserLog" && (
            <TabContainer>
              <UserLog userId={userId} />
            </TabContainer>
          )}
          {section === "PhysicalCards" && (
            <TabContainer>
              <PhysicalCards userId={userId} />
            </TabContainer>
          )}
          {section === "VirtualCards" && (
            <TabContainer>
              <VirtualCards userId={userId} />
            </TabContainer>
          )}
          {section === "Settings" && (
            <TabContainer>
              <UserSettings userId={userId} />
            </TabContainer>
          )}
          {section === "Fees" && (
            <TabContainer>
              <UserFees userId={userId} />
            </TabContainer>
          )}
        </div>
      </div>
    </Auxiliary>
  );
};

export default Profile;
