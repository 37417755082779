import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { downloadFileAPI } from "services/API/fileManager";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileSaver from "file-saver";

let PDFJS = window.pdfjsLib;

const ImagePreview = ({ fileAddress, label }) => {
  const [image, setImage] = useState(null);
  const [base64Data, setBase64Data] = useState(null);

  async function showImage() {
    try {
      let response = await downloadFileAPI({ fileAddress });
      let base64Data = `data:${response.data.type};base64,${response.data.value}`;
      if (response.status === "success") {
        setBase64Data(base64Data);
        if (response.data.type.includes("pdf")) {
          let _PDF_DOC = await PDFJS.getDocument(base64Data);
          renderPage(_PDF_DOC);
        } else {
          setImage(base64Data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    if (fileAddress) {
      showImage();
    }
  }, [fileAddress]);

  async function renderPage(pdf) {
    const canvas = document.createElement("canvas");

    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    console.log("page lenght", pdf.numPages);

    await page.render(render_context).promise;
    let img = canvas.toDataURL("image/png");
    setImage(img);
  }

  return (
    <Card variant="outlined">
      <CardHeader
        subheader={label}
        subheaderTypographyProps={{ sx: { textAlign: "center" } }}
      />
      <CardContent>
        <img src={image} alt="" style={{ width: "100%" }} loading="eager" />
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <IconButton onClick={() => FileSaver.saveAs(base64Data, label)}>
          <FileDownloadOutlinedIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default ImagePreview;
