import {
  GET_PHYSICAL_CARDS_LIST_FAILURE,
  GET_PHYSICAL_CARDS_LIST_STARTED,
  GET_PHYSICAL_CARDS_LIST_SUCCESS,
  GET_VIRTUAL_CARDS_LIST_FAILURE,
  GET_VIRTUAL_CARDS_LIST_STARTED,
  GET_VIRTUAL_CARDS_LIST_SUCCESS,
  LOGIN_DEFAULT,
  VERIFY_SUCCESS,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_DEFAULT,
  physicalCards: { records: [], count: 0 },
  virtualCards: { records: [], count: 0 },
  error: "",
};
export const CardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PHYSICAL_CARDS_LIST_STARTED:
    case GET_VIRTUAL_CARDS_LIST_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_PHYSICAL_CARDS_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_PHYSICAL_CARDS_LIST_SUCCESS,
        physicalCards: action.payload.physicalCards,
        error: "",
      };
    case GET_VIRTUAL_CARDS_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_VIRTUAL_CARDS_LIST_SUCCESS,
        virtualCards: action.payload.virtualCards,
        error: "",
      };
    case GET_PHYSICAL_CARDS_LIST_FAILURE:
    case GET_VIRTUAL_CARDS_LIST_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
