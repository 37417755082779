import React, { useEffect, useState } from "react";
import UserInfo from "./UserInfo";
import StatusLoading from "../StatusLoading";
import CardHeader from "components/CardHeader";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetails } from "redux/actions/usersActions";
import UserPersonal from "./UserInfo/UserPersonal";
import UserAddress from "./UserInfo/UserAddress";
import UserNationality from "./UserInfo/UserNationality";

const Summary = () => {
  const dispatch = useDispatch();
  const { id: userId } = useParams();
  const [user, setUser] = useState({});
  const [address, setAddress] = useState({})
  const [nationality, setNationality] = useState({})
  const [personal, setPersonal] = useState({})
  console.log("🚀 ~ file: index.js:13 ~ Summary ~ user:", user)

  useEffect(() => {
    if (userId) dispatch(getUserDetails({ setUser, userId }));
  }, [userId])

  useEffect(() => {
    setAddress(user.Address)
    setNationality(user.Nationality)
    setPersonal(user.Personal)
  }, [user])
  return (
    <div className="jr-card jr-full-card">
      <CardHeader title="summary" status={user?.status} />
      <div style={{ padding: "24px" }}>
        {personal && <UserPersonal data={personal} setData={setPersonal} />}
        <br></br>
        {address && <UserAddress data={address} setData={setAddress} />}
        <br></br>
        {nationality && <UserNationality data={nationality} setData={setNationality} />}
      </div>
    </div>
  );
};

export default Summary;
