import LoadingButton from "@mui/lab/LoadingButton";
// --------------------------------------------------------------------------------
const Button = ({
  text,
  type,
  variant = "contained",
  color = "primary",
  fullWidth = true,
  onClick,
  loading,
  ...otherProps
}) => {
  return (
    <LoadingButton
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      fullWidth={fullWidth}
      loading={loading}
      {...otherProps}
    >
      {text}
    </LoadingButton>
  );
};

export default Button;
