import APIs from "services/API/index";

export const formItems = [
  {
    name: "firstName",
    type: "text",
    label: "First Name",
    values: null,
    required: true,
  },
  {
    name: "lastName",
    type: "text",
    label: "Last Name",
    values: null,
    required: true,
  },
  {
    name: "mobile",
    type: "text",
    label: "Mobile",
    values: null,
    required: true,
  },
  {
    name: "email",
    type: "text",
    label: "Email",
    values: null,
    required: true,
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    values: null,
    required: false,
  },
  {
    name: "roleID",
    type: "multipleSelect",
    label: "Role",
    schema: { label: "name", value: "id" },
    requestOnMount: true,
    apiAddress: APIs.apiRoleList,
  },
  {
    name: "agentID",
    type: "multipleSelect",
    label: "Agent",
    schema: { label: "name", value: "id" },
    requestOnMount: true,
    apiAddress: APIs.apiAgentList,
  },
  // {
  //   name: "agentID",
  //   type: "multipleSelect",
  //   label: "Agents",
  //   schema: { label: "name", value: "id" },
  //   requestOnMount: true,
  //   apiAddress: APIs.apiAgentList,
  // },
];
