import axiosMiddleware from "./axiosMiddleware";

export const getUsersLogAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `log/list?limit=${limit}&offset=${offset}`,
  });
};
export const getPepLogsAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `log/pep/list?limit=${limit}&offset=${offset}`,
  });
};
