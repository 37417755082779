import {
  CONFIRM_KYC_FAILURE,
  CONFIRM_KYC_STARTED,
  CONFIRM_KYC_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_STARTED,
  GET_ALL_USERS_SUCCESS,
  GET_APPROVED_USERS_FAILURE,
  GET_APPROVED_USERS_STARTED,
  GET_APPROVED_USERS_SUCCESS,
  GET_KNOW_YOUR_CUSTOMER_FAILURE,
  GET_KNOW_YOUR_CUSTOMER_STARTED,
  GET_KNOW_YOUR_CUSTOMER_SUCCESS,
  GET_PENDING_USERS_FAILURE,
  GET_PENDING_USERS_STARTED,
  GET_PENDING_USERS_SUCCESS,
  GET_PREMIUM_USERS_FAILURE,
  GET_PREMIUM_USERS_STARTED,
  GET_PREMIUM_USERS_SUCCESS,
  GET_STARTER_USERS_FAILURE,
  GET_STARTER_USERS_STARTED,
  GET_STARTER_USERS_SUCCESS,
  GET_USER_DEFAULTS_FAILURE,
  GET_USER_DEFAULTS_STARTED,
  GET_USER_DEFAULTS_SUCCESS,
  GET_USER_FEES_SUCCESS,
  LOGIN_DEFAULT,
  MAKE_PREMIUM_USER_FAILURE,
  MAKE_PREMIUM_USER_STARTED,
  MAKE_STARTER_USER_FAILURE,
  MAKE_STARTER_USER_STARTED,
  MAKE_STARTER_USER_SUCCESS,
  VERIFY_SUCCESS,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_DEFAULT,
  kycFields: [],
  allUsers: { records: [], count: 0 },
  pendingUsers: { records: [], count: 0 },
  userDefaults: [],
  starterUsers: { records: [], count: 0 },
  premiumUsers: { records: [], count: 0 },
  approvedUsers: { records: [], count: 0 },
  pepAccountStatus: null,
  error: "",
};
export const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_STARTED:
    case GET_PENDING_USERS_STARTED:
    case GET_KNOW_YOUR_CUSTOMER_STARTED:
    case CONFIRM_KYC_STARTED:
    case GET_USER_DEFAULTS_STARTED:
    case GET_STARTER_USERS_STARTED:
    case GET_PREMIUM_USERS_STARTED:
    case GET_APPROVED_USERS_STARTED:
    case MAKE_STARTER_USER_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case CONFIRM_KYC_SUCCESS:
    case MAKE_STARTER_USER_SUCCESS:
    case MAKE_PREMIUM_USER_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        rType: GET_ALL_USERS_SUCCESS,
        allUsers: action.payload.allUsers,
        error: "",
      };
    case GET_PENDING_USERS_SUCCESS:
      return {
        ...state,
        rType: GET_PENDING_USERS_SUCCESS,
        pendingUsers: action.payload.pendingUsers,
        error: "",
      };
    case GET_APPROVED_USERS_SUCCESS:
      return {
        ...state,
        rType: GET_APPROVED_USERS_SUCCESS,
        approvedUsers: action.payload.approvedUsers,
        error: "",
      };
    case GET_KNOW_YOUR_CUSTOMER_SUCCESS:
      return {
        ...state,
        rType: VERIFY_SUCCESS,
        kycFields: action.payload.kycFields,
        pepAccountStatus: action.payload.pepAccountStatus,
        userStatus: action.payload.userStatus,
        email: action.payload.email,
        mobile: action.payload.mobile,
        error: "",
      };
    case GET_USER_DEFAULTS_SUCCESS:
      return {
        ...state,
        rType: GET_USER_DEFAULTS_SUCCESS,
        userDefaults: action.payload.userDefaults,
        error: "",
      };
    case GET_USER_FEES_SUCCESS:
      return {
        ...state,
        rType: GET_USER_FEES_SUCCESS,
        userDefaults: action.payload.userFees,
        error: "",
      };
    case GET_STARTER_USERS_SUCCESS:
      return {
        ...state,
        rType: GET_STARTER_USERS_SUCCESS,
        starterUsers: action.payload.starterUsers,
        error: "",
      };
    case GET_PREMIUM_USERS_SUCCESS:
      return {
        ...state,
        rType: GET_PREMIUM_USERS_SUCCESS,
        premiumUsers: action.payload.premiumUsers,
        error: "",
      };
    case GET_ALL_USERS_FAILURE:
    case GET_PENDING_USERS_FAILURE:
    case GET_KNOW_YOUR_CUSTOMER_FAILURE:
    case CONFIRM_KYC_FAILURE:
    case GET_USER_DEFAULTS_FAILURE:
    case GET_STARTER_USERS_FAILURE:
    case GET_PREMIUM_USERS_FAILURE:
    case GET_APPROVED_USERS_FAILURE:
    case MAKE_STARTER_USER_FAILURE:
    case MAKE_PREMIUM_USER_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
