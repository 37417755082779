import { hideMessage, showMessage } from "components/Modal";
import {
  GET_ADMIN_DEFAULTS_STARTED,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  GET_ADMIN_DEFAULTS_FAILURE,
  EDIT_WALLET_MINIMUM_BALANCE_STARTED,
  EDIT_WALLET_MINIMUM_BALANCE_SUCCESS,
  EDIT_WALLET_MINIMUM_BALANCE_FAILURE,
} from "redux/ActionTypes";
import { editAdminWalletMinimumBalanceAPI, getAdminBalanceAPI, getAdminFeesListAPI, getAdminInfoAPI, getAdminWalletAPI, getSuperAdminBalanceAPI } from "services/API/admins";
import { setAdminFeesAPI } from "services/API/users";


export const getAdminDefaultsStartedAction = () => ({
    type: GET_ADMIN_DEFAULTS_STARTED,
});

export const getAdminsSuccessAction = (payload) => ({
    type: GET_ADMIN_SUCCESS,
    payload,
});

export const getAdminsFailureAction = (message) => ({
    type: GET_ADMIN_FAILURE,
    payload: message,
  });

export const getAdminDefaultsFailureAction = (message) => ({
    type: GET_ADMIN_DEFAULTS_FAILURE,
    payload: message,
});

export const editAdminWalletMinimumBalanceStartedAction = () => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_STARTED,
});

export const editAdminWalletMinimumBalancesSuccessAction = (payload) => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_SUCCESS,
  payload,
});

export const editAdminWalletMinimumBalancesFailureAction = (message) => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_FAILURE,
  payload: message,
});
  
export const getAdminWallet = ({setData,adminId}) => {
    
    return async (dispatch) => {
       dispatch(getAdminDefaultsStartedAction());
       try {
         let response = await getAdminWalletAPI(adminId);

         if(!response.error){
            dispatch(getAdminsSuccessAction());
            setData(response.data);

         }else{
            dispatch(getAdminsFailureAction(response.data.error_description));
         }
        } catch (error) {
            dispatch(getAdminsFailureAction(error.error_description))
        }
    }
}

export const getAdminInfo = ({ setAdmin, adminId }) => {
    return async dispatch => {
      dispatch(getAdminDefaultsStartedAction());
      try {
        let response = await getAdminInfoAPI({ adminId });
        if (!response.error) {
          dispatch(getAdminsSuccessAction());
          setAdmin(response.data);
        } else {
          dispatch(getAdminsFailureAction(response.data.error_description));
        }
      } catch (error) {
        dispatch(getAdminsFailureAction(error.error_description));
      }
    };
};

export const getAdminFeesListAction = (adminId, setFees) => {
  return async (dispatch) => {
    try {
      let response = await getAdminFeesListAPI(adminId);
      if (!response.error) {
        setFees(response.data);
         dispatch(getAdminsSuccessAction(response.data));
      } else {
         dispatch(getAdminsFailureAction(response.data.error_description));
      }
    } catch (error) {
       dispatch(getAdminsFailureAction(error.error_description));
    }
  };
};

export const setAdminFeesRequestAction = (data, setLoading, setFee) => {
  return async (dispatch) => {
    setLoading(true);
    dispatch(getAdminDefaultsStartedAction());
    try {
      let response = await setAdminFeesAPI(data);
      if (!response.error) {
        setLoading(false);
        setFee(response.data);
        showMessage({
          onSubmit: () => {
            hideMessage();
          },
        });
      } else {
        setLoading(false);
        dispatch(
          getAdminDefaultsFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
        onSubmit: () => {
          hideMessage();
        },
      });
      dispatch(getAdminDefaultsFailureAction(error.error_description));
    }
  };
};

export const getAdminBalanceAction = (walletId , setAdminBalance) => {
  return async dispatch => {
     
     try {
      let response = await getAdminBalanceAPI(walletId);
      if(!response.error){
       
        if(response.data <=0) setAdminBalance(0);
        setAdminBalance(response.data)
      }
     } catch (error) {
       console.log(error)
     }
  }
}

export const getSuperAdminBalanceAction = (walletId, setAdminBalance) => {
  return async (dispatch) => {
    try {
      let response = await getSuperAdminBalanceAPI(walletId);
      if (!response.error) {
        if (response.data <= 0) setAdminBalance(0);
        setAdminBalance(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const editAdminWalletMinimumBalanceRequestAction = ({
  walletId,
  minBalance,
  setData,
  adminId,
}) => {
  return async (dispatch) => {
    dispatch(editAdminWalletMinimumBalanceStartedAction());
    try {
      let response = await editAdminWalletMinimumBalanceAPI({
        walletId,
        minBalance,
      });
      if (!response.error) {
        dispatch(editAdminWalletMinimumBalancesSuccessAction());
        // dispatch(getUserWallet({ userId, setData }));
      } else {
        dispatch(
          editAdminWalletMinimumBalancesFailureAction(
            response.data.error_description
          )
        );
      }
    } catch (error) {
      dispatch(editAdminWalletMinimumBalancesFailureAction(error.error_description));
    }
  };
};