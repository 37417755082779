import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppDatePicker from "components/AppDatePicker";
import Dayjs from "dayjs";

const withDateRangePicker = (WrappedComponent) => (props) => {
  const [from, setFrom] = useState(Dayjs().subtract(7, "day"));
  const [to, setTo] = useState(Dayjs().add(1, "day"));

  return (
    <WrappedComponent
      from={from}
      to={to}
      renderTopToolbar={(filters) => (
        <Grid container spacing={2} sx={{ px: 2, pb: 2 }}>
          <Grid item>
            <AppDatePicker
              label="From"
              value={from}
              onChange={(e) => setFrom(e)}
            />
          </Grid>
          <Grid item>
            <AppDatePicker label="To" value={to} onChange={(e) => setTo(e)} />
          </Grid>
          {filters}
        </Grid>
      )}
      {...props}
    />
  );
};

export default withDateRangePicker;
