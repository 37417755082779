import {
  GET_AGENT_TOP_UP_LIST_FAILURE,
  GET_AGENT_TOP_UP_LIST_STARTED,
  GET_AGENT_TOP_UP_LIST_SUCCESS,
  LOGIN_DEFAULT,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_DEFAULT,
  agentTopUpList: [],
  error: "",
};
export const TopUpAgentReducer = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case GET_AGENT_TOP_UP_LIST_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_AGENT_TOP_UP_LIST_SUCCESS:
      console.log({
        ...state,
        rType: GET_AGENT_TOP_UP_LIST_SUCCESS,
        agentTopUpList: action.payload.agentTopUpList,
        error: "",
      });
      return {
        ...state,
        rType: GET_AGENT_TOP_UP_LIST_SUCCESS,
        agentTopUpList: action.payload.agentTopUpList,
        error: "",
      };

    case GET_AGENT_TOP_UP_LIST_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
