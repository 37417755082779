import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersRequestAction } from "./../../../../../redux/actions/customerActions";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
import DropDown from "components/DropDown";
import CustomTable from "components/CustomTable";
import withDateRangePicker from "HOC/withDateRangePicker";
import { superAdmin } from "constant";
// --------------------------------------------------------------------------------
const AllUsers = ({ renderTopToolbar, from, to }) => {
  const roles = useSelector((state) => state.auth.user.roles);

  const dispatch = useDispatch();

  const [agent, setAgent] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const users = useSelector((state) => state.customer.allUsers);
  const agentList = useSelector((state) => state.agent.agentsList);
  const permissions = useSelector((state) => state.auth.user.permissions);

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllUsersRequestAction({
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
        from: from.format("YYYY-MM-DD"),
        to: to.format("YYYY-MM-DD"),
        agent,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize, from, to, agent, dispatch]);

  useEffect(() => {
    setAllUsers(users);
    setAgentsList(agentList);
  }, [users, agentList]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User's Full Name",
        size: 150,
        Cell: ({ cell }) => <span>{cell.getValue()}</span>,
      },
      {
        accessorKey: "agent",
        header: "Agent",
        size: 150,
      },
      {
        accessorKey: "email", // normal accessorKey
        header: "Email",
        Cell: ({ cell, row }) => {
          return permissions.some((p) => p === "See Profile User") ? (
            <Link to={`/app/profile/${row.original.id}/Summary`}>
              {cell.getValue()}
            </Link>
          ) : (
            <span>{cell.getValue()}</span>
          );
        },
      },
      {
        accessorKey: "country_code",
        header: "Mobile Country Code",
        size: 150,
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        size: 150,
      },
      {
        accessorKey: "pepStatus",
        header: "PeP Status",
        size: 150,
      },
      {
        accessorKey: "registerTime",
        header: "Register Time",
        size: 150,
      },
    ];

    // Conditionally add columns based on roles
    if (roles.some((role) => role.name === superAdmin)) {
      baseColumns.push(
        {
          accessorKey: "kycStatus",
          header: "KYC Status",
          size: 150,
        },
        {
          accessorKey: "status",
          header: "User Status",
          size: 150,
        },
        {
          accessorKey: "pepId",
          header: "PeP ID",
          size: 150,
        }
      );
    }

    return baseColumns;
  }, [permissions, roles, superAdmin]);

  return (
    <CustomTable
      label="All Users"
      columns={columns}
      data={allUsers.count > 0 && allUsers.records}
      renderTopToolbar={() =>
        renderTopToolbar(
          <Grid item>
            <Box sx={{ width: 238 }}>
              <DropDown
                label="Agent"
                schema={{ label: "name", value: "id" }}
                items={
                  agentsList.records
                    ? [{ name: "ALL", id: 0 }, ...agentsList.records]
                    : [{ name: "ALL", id: 0 }]
                }
                value={agent}
                onChange={(event) => setAgent(event.target.value)}
              />
            </Box>
          </Grid>
        )
      }
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={allUsers.count}
    />
  );
};

export default withDateRangePicker(AllUsers);
