/* eslint-disable react/jsx-no-target-blank */
import React from "react";
// import moment from "moment-jalaali";
import { boot, StorageBaseURL } from "./constants";
import { Clear, Done } from "@mui/icons-material";
import DateTooltip from "components/DateTooltip";
import { store } from "redux/store";

export function createDateLookup() {
  return {
    title: "Created At",
    field: "created_at",
    editable: "never",
    render: (data) => <DateTooltip data={data?.created_at} />,
  };
}

export function updateDateLookup() {
  return {
    title: "Updated At",
    field: "updated_at",
    editable: "never",
    render: (data) => <DateTooltip data={data?.updated_at} />,
  };
}

export function renderAmount(data, key = "amount") {
  return thousands_separators(
    data.currency_id
      ? data.currency_id === 3
        ? Math.trunc(data[key])
        : parseFloat(data[key])?.toFixed(2)
      : data[key]
  );
}

export function dateLookup({ field, title }) {
  return {
    title,
    field,
    editable: "never",
    render: (data) => <DateTooltip data={data[field]} />,
  };
}

export const renderTicket = (ticket) => (
  <a target="_blank" href={"/app/tickets/ticket-page/" + ticket.id}>
    {ticket?.subject}
  </a>
);

export function deleteDateLookup({ restoreFunc }) {
  return {
    title: "Deleted At",
    field: "deleted_at",
    editable: "never",
    render: (data) => (
      <DateTooltip
        data={data?.deleted_at}
        rowData={data}
        restoreFunc={restoreFunc}
      />
    ),
  };
}

export const renderMobile = (user) => (
  <>
    <span>{user?.mobile}</span>{" "}
    {user?.mobile_status === "Active" ? (
      <Done className="text-green" />
    ) : (
      <Clear className="text-red" />
    )}
  </>
);

export const renderLink = ({ text, href, target = "_blank" }) => {
  return (
    text &&
    href && (
      <a
        style={{
          textDecoration: "none",
          fontSize: "14px",
        }}
        target={target}
        href={href}
      >
        {text}
      </a>
    )
  );
};
export function getUser(user) {
  return (
    user && (
      <a
        style={{ textDecoration: "none" }}
        target="_blank"
        href={"/app/profile/user/" + user.id}
      >
        {user.full_name}
      </a>
    )
  );
}
export function getYoutubeUser(user) {
  return user?.id ? (
    <a
      style={{ textDecoration: "none" }}
      target="_blank"
      href={"/app/profile/user/" + user.id}
    >
      {user.first_name} {user.last_name}
    </a>
  ) : (
    <p>
      {user.first_name} {user.last_name}
    </p>
  );
}
export function getYoutubeUserEmail(user) {
  return user?.id ? (
    <a
      style={{ textDecoration: "none" }}
      target="_blank"
      href={"/app/profile/user/" + user.id}
    >
      {user.email}
    </a>
  ) : (
    <p>{user.email}</p>
  );
}
export function getEPUser(user) {
  return (
    user && (
      <a
        style={{ textDecoration: "none" }}
        target="_blank"
        href={"/app/exchange-panel/profile/user/" + user.id}
      >
        {user.first_name} {user.last_name}
      </a>
    )
  );
}
export function getUserEmail(user) {
  return (
    user && (
      <a
        style={{ textDecoration: "none" }}
        target="_blank"
        href={"/app/profile/user/" + user.id}
      >
        {user.email}
      </a>
    )
  );
}
export function getEPUserEmail(user) {
  return (
    user && (
      <a
        style={{ textDecoration: "none" }}
        target="_blank"
        href={"/app/exchange-panel/profile/user/" + user.id}
      >
        {user.email}
      </a>
    )
  );
}
export const renderAdmin = () => {
  return {
    title: "Admin",
    field: "admin_id",
    render: (data) =>
      boot?.admins && data?.admin_id
        ? boot.admins.find((admin) => admin.id === data.admin_id).first_name +
          " " +
          boot.admins.find((admin) => admin.id === data.admin_id).last_name
        : "-",
  };
};

export const renderCountryName = (data) =>
  boot.countries && data
    ? boot.countries.find((country) => country.id === data).name_en
    : "-";

export const getIdentityStatus = (data) => {
  if (data === "Approved") {
    data = <span className="text-success">{data}</span>;
  }

  if (data === "Declined") {
    data = <span className="text-danger">{data}</span>;
  }

  if (data === "Pending") {
    data = <span className="text-warning">{data}</span>;
  }
  if (data === "Suspend") {
    data = <span className="text-danger">{data}</span>;
  }
  return data;
};

export const attachment = (data) => {
  if (data === "Receipt") {
    data = <span className="text-success">{data}</span>;
  }

  if (data === "Invoice") {
    data = <span className="text-danger">{data}</span>;
  }
  return data;
};

export const getIbanInquiry = (data) => {
  return (
    data !== null && (
      <a
        style={{ textDecoration: "none" }}
        href={`/app/system/iban-inquiry/${data}`}
        className="mb-0 jr-fs-lg"
        target="_blank"
      >
        {data}
      </a>
    )
  );
};

export const renderStatus = (data) => {
  if (data === "Approved") {
    data = <span className="text-success">{data}</span>;
  }

  if (data === "Declined") {
    data = <span className="text-danger">{data}</span>;
  }

  if (data === "Pending") {
    data = <span className="text-warning">{data}</span>;
  }
  if (data === "Suspend") {
    data = <span className="text-danger">{data}</span>;
  }
  if (data === "Trial") {
    data = <span className="text-warning">{data}</span>;
  }
  if (data === "Canceled") {
    data = <span className="text-danger">{data}</span>;
  }
  return data;
};

export const getAttachment = (data, baseURL = StorageBaseURL) => {
  if (data) {
    return (
      <a className="ml-3" target="_blank" href={baseURL + data}>
        <i
          className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary "
          style={{ fontSize: "24px" }}
        />
      </a>
    );
  }
};

export const getIdentityStatus2 = (data) => {
  if (data === "Approved") {
    data = <span className="text-success">{data}</span>;
  }

  if (data === "Declined") {
    data = <span className="text-danger">{data}</span>;
  }

  if (data === "Pending") {
    data = <span className="text-warning">{data}</span>;
  }
  if (data === "Suspend") {
    data = <span className="text-danger">{data}</span>;
  }
  if (data === "Trial") {
    data = <span className="text-warning">{data}</span>;
  }
  if (data === "Canceled") {
    data = <span className="text-danger">{data}</span>;
  }
  return data;
};
export const getYekpayLinkStatus = (data) => {
  if (data === "Active") {
    data = <span className="text-success">{data}</span>;
  }
  if (data === "Inactive") {
    data = <span className="text-danger">{data}</span>;
  }
  if (data === "Suspend") {
    data = <span className="text-danger">{data}</span>;
  }
  return data;
};

export const getToggleStatus = (data) => {
  if (data === "Active") {
    data = <span className="text-success">{data}</span>;
  }
  if (data === "Inactive") {
    data = <span className="text-danger">{data}</span>;
  }
  return data;
};

export const getToggleStatus2 = (data) => {
  if (data === "Successful") {
    data = <span className="text-success">{data}</span>;
  }
  if (data === "Failed") {
    data = <span className="text-danger">{data}</span>;
  }
  return data;
};

export const getLogLevel = (data) => {
  if (data === "DEBUG") {
    data = <span className="text-success">{data}</span>;
  }

  if (data === "INFO") {
    data = <span className="text-warning">{data}</span>;
  }

  if (data === "ERROR") {
    data = <span className="text-danger">{data}</span>;
  }
  return data;
};
export const eventCode = (data) => {
  if (data.event_id === 1) {
    data = "t" + data.id;
  } else if (data.event_id === 3) {
    data = "g" + data.id;
  } else if (data.event_id === 9) {
    data = "p" + data.id;
  } else if (data.event_id === 12) {
    data = "e" + data.id;
  } else {
    data = data.id;
  }
  return data;
};

// Print an integer with commas as thousands separators
export function thousands_separators(num) {
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}
export function Gregorian(data, date) {
  if (date.isGregorian === true) {
  } else {
    // moment(data).format("jYYYY-jMM-jDD HH:mm:ss");
  }
  return data;
}

export const dateFormatter = (date, isGregorian) => {
  if (!date) return;
  // if (isGregorian) return moment(date).format("jYYYY-jMM-jDD HH:mm:ss");
  // return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export function lookup(boot_array) {
  let array_target = boot_array?.map((item) => {
    return item.name_en;
  });
  let obj_target = new Object();
  for (let i = 0; i < array_target?.length; i++) {
    obj_target[i + 1] = array_target[i];
  }
  return obj_target;
}

export function lookup2(boot_array) {
  let array_value = boot_array?.map((item) => {
    return item.first_name + item.last_name;
  });
  let array_key = boot_array?.map((item) => {
    return item.id;
  });
  let obj_target = new Object();
  for (let i = 0; i < array_value?.length; i++) {
    obj_target[array_key[i]] = array_value[i];
  }
  return obj_target;
}

export function lookup3(boot_array) {
  let filteredAry = boot_array?.filter(function (e) {
    return e.parent_id !== null;
  });
  let array_value = filteredAry?.map((item) => {
    return item.category_en;
  });
  let obj_target = new Object();
  for (let i = 0; i < array_value?.length; i++) {
    obj_target[array_value[i]] = array_value[i];
  }
  return obj_target;
}

export function lookup4(boot_array) {
  let filteredAry = boot_array?.filter(function (e) {
    return e.parent_id === null;
  });
  let array_value = filteredAry?.map((item) => {
    return item.category_en;
  });
  let obj_target = new Object();
  for (let i = 0; i < array_value?.length; i++) {
    obj_target[array_value[i]] = array_value[i];
  }
  return obj_target;
}

export const checkDirection = (content) => {
  let dir;
  const letters = /^[A-Za-z-0-9_.-@-#-%-!-*-=-^-" "|]*$/gi;
  let isMatch = letters.exec(content);
  if (isMatch) {
    dir = "ltr";
  } else {
    dir = "rtl";
  }
  return dir;
};

export const logOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("admin_id");
  window.location = "/signin";
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatLables = (label) => {
  const splitedLabel = label.replaceAll("_", " ").split(" ");
  const capitilizedArr = splitedLabel?.map((word) =>
    capitalizeFirstLetter(word)
  );
  return capitilizedArr.join(" ");
};

export const getCurrencies = (extraItem) => {
  const boot = JSON.parse(localStorage.getItem("boot"));
  if (extraItem) return [...extraItem, ...boot.currencies];
  return boot?.currencies;
};

export const getAllUsers = (extraItem) => {
  const boot = JSON.parse(localStorage.getItem("boot"));
  if (extraItem) return [...extraItem, ...boot.users];
  return boot?.users;
};

export const getAdmins = () => {
  const boot = JSON.parse(localStorage.getItem("boot"));
  return boot?.admins;
};

export const getCountries = () => {
  const boot = JSON.parse(localStorage.getItem("boot"));
  return boot?.countries;
};
export const formDataGenerator = (values) => {
  const formData = new FormData();

  Object.keys(values)?.map((key) => {
    formData.append([key], values[key]);
    return null;
  });

  return formData;
};
export const dateFormatForServer = (values) => {
  // return moment(values).format("YY/MM/DD HH:MM:SS");
};

export const camelCaseToTitleCase = (input) => {
  // Split the input string into words
  const words = input.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  // Capitalize the first letter of each word and join them back together
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the title case words to form the final title case string
  const titleCaseString = titleCaseWords.join(" ");

  return titleCaseString;
};

export const removeBalanceSeparator = (balanceWithSeparator) => {
  // Check if balanceWithSeparator is a string and contains any commas
  if (
    typeof balanceWithSeparator === "string" &&
    balanceWithSeparator.includes(",")
  ) {
    // Use regular expression to replace all separators (, in this case)
    // g flag stands for global, which replaces all occurrences
    return balanceWithSeparator.replace(/,/g, "");
  } else {
    // If it's not a string or there are no commas, return the original balanceWithSeparator
    return balanceWithSeparator;
  }
};

export const snakeCaseToTitleCase = (input) => {
  // Split the string into words based on underscores
  const words = input.split("_");

  // Capitalize the first letter of each word
  const titleCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back together into a single string
  const titleCaseString = titleCaseWords.join(" ");

  return titleCaseString;
};

export const toFixedWithoutZeros = (num, precision = 2) =>
  Number(num).toFixed(precision); //.replace(/\.0+$/, "")
