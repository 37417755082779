import React, { useMemo, useState } from "react";
import { API_ROLE_LIST } from "constants/constants";
import withTable from "HOC/withTable/withTable";
import APIs from "services/API/index";
import useAPI from "services/hook/index";
import MessageDialog, { CONFIRMATION_DIALOG } from "components/MessageDialog";
import { useNavigate } from "react-router-dom";
import CustomTable from "components/CustomTable";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { format } from "date-fns";

const RoleList = ({ data, reRender }) => {
  const navigate = useNavigate();
  const [dialogProps, setDialogProps] = useState({ isOpen: false, type: "" });

  const apiDeleteRole = useAPI(APIs.apiDeleteRole);

  const handleDeleteRole = async (id) => {
    const result = await apiDeleteRole.request({ id });
   
    if (result) reRender();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Role Name",
        size: 150,
      },
     

      {
        accessorKey: "edit",
        header: "Edit",
        Cell: ({ row }) => {
          if (!/super[-\s]?admin/i.test(row.original.name)) {
            return (
          
          <IconButton
            onClick={() =>
              navigate(`/app/role/edit/${row.original.id}`, { state: { name: row.original.name, permissions: row.original.permissions } })
            }
          >
            <EditIcon color="primary" />
          </IconButton>
        )
        }
        }
          
      },

      {
        accessorKey: "delete",
        header: "Delete",
        Cell: ({ row }) => {
          if (!/super[-\s]?admin/i.test(row.original.name)) {
            return (
              <IconButton
               onClick={() =>
              setDialogProps({
                isOpen: true,
                type: CONFIRMATION_DIALOG,
                onConfirm: () => handleDeleteRole(row.original.id),
              })
            }
          >
            <DeleteOutlineIcon color="error" />
          </IconButton>
            )
          }
        },
      },
       {
        accessorKey: "created_at",
        header: "Created At",
        size: 150,
        accessorFn: (row) =>
          format(new Date(row.created_at), "yyyy/MM/dd HH:mm:ss")
      },
    ],
    []
  );

  return (
   <>
    <CustomTable
      label="Role List"
      data={data}
      columns={columns}
      manualPagination={false}
    />
      {dialogProps.isOpen && (
        <MessageDialog
          isOpen={dialogProps.isOpen}
          type={dialogProps.type}
          status="warning"
          title="Delete Confirmation"
          description="Are you sure you want to delete this role?"
          onConfirm={dialogProps.onConfirm} 
          onCancel={() => setDialogProps({ isOpen: false, type: "" })}
        />
      )}
   </>
  );
};

export default withTable(RoleList, {
  title: "Role List",
  apiAddress: API_ROLE_LIST,
  hasDatePicker: false,
});
