import {
  CREATE_TOP_UP_IBAN_FAILURE,
  CREATE_TOP_UP_IBAN_STARTED,
  CREATE_TOP_UP_IBAN_SUCCESS,
  DELETE_TOP_UP_IBAN_FAILURE,
  DELETE_TOP_UP_IBAN_STARTED,
  DELETE_TOP_UP_IBAN_SUCCESS,
  GET_AGENT_TOP_UP_LIST_STARTED,
  GET_AGENT_TOP_UP_LIST_SUCCESS,
  GET_AGENT_TOP_UP_LIST_FAILURE,
  GET_TOP_UP_BANKS_FAILURE,
  GET_TOP_UP_BANKS_STARTED,
  GET_TOP_UP_BANKS_SUCCESS,
  GET_TOP_UP_COUNTRIES_FAILURE,
  GET_TOP_UP_COUNTRIES_STARTED,
  GET_TOP_UP_COUNTRIES_SUCCESS,
  GET_TOP_UP_IBAN_LIST_FAILURE,
  GET_TOP_UP_IBAN_LIST_STARTED,
  GET_TOP_UP_IBAN_LIST_SUCCESS,
  GET_TOP_UP_LIST_FAILURE,
  GET_TOP_UP_LIST_STARTED,
  GET_TOP_UP_LIST_SUCCESS,
} from "redux/ActionTypes";
import {
  createTopUpIbanAPI,
  deleteTopUpIbanAPI,
  getAgentTopUpListAPI,
  getTopUpBanksAPI,
  getTopUpCountriesAPI,
  getTopUpIbanListAPI,
  getTopUpListAPI,
} from "services/API/topup";
// --------------------------------------------------------------------------------
export const getTopUpListRequestAction = () => {
  return async (dispatch) => {
    dispatch(getTopUpListStartedAction());
    try {
      let response = await getTopUpListAPI();
      console.log(response)
      if (!response.error) {
        dispatch(getTopUpListSuccessAction({ topUpList: response.data }));
      } else {
        dispatch(getTopUpListFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getTopUpListFailureAction(error.error_description));
    }
  };
};

export const getTopUpListStartedAction = () => ({
  type: GET_TOP_UP_LIST_STARTED,
});

export const getTopUpListSuccessAction = (payload) => ({
  type: GET_TOP_UP_LIST_SUCCESS,
  payload,
});

export const getTopUpListFailureAction = (message) => ({
  type: GET_TOP_UP_LIST_FAILURE,
  payload: message,
});


export const getTopUpAgentListRequestAction = () => {
  return async (dispatch) => {
    dispatch(getTopUpAgentListStartedAction());
    try {
      let response = await getAgentTopUpListAPI();
      console.log(response);
      if (!response.error) {
        dispatch(
          getTopUpAgentListSuccessAction({ agentTopUpList: response.data })
        );
      } else {
        dispatch(getTopUpListFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getTopUpAgentListFailureAction(error.error_description));
    }
  };
};

export const getTopUpAgentListStartedAction = () => ({
  type: GET_AGENT_TOP_UP_LIST_STARTED,
});

export const getTopUpAgentListSuccessAction = (payload) => ({
  type: GET_AGENT_TOP_UP_LIST_SUCCESS,
  payload,
});

export const getTopUpAgentListFailureAction = (message) => ({
  type: GET_AGENT_TOP_UP_LIST_FAILURE,
  payload: message,
});

// --------------------------------------------------------------------------------
export const getTopUpCountriesAction = ({ walletId }) => {
  return async (dispatch) => {
    dispatch(getTopUpCountriesStartedAction());
    try {
      let response = await getTopUpCountriesAPI({ walletId });
      if (response.status === "success") {
        dispatch(
          getTopUpCountriesSuccessAction({ topUpCountries: response.data })
        );
      } else {
        getTopUpCountriesFailureAction(response.data.error_description);
      }
    } catch (error) {
      dispatch(getTopUpCountriesFailureAction(error.error_description));
    }
  };
};

export const getTopUpCountriesStartedAction = () => ({
  type: GET_TOP_UP_COUNTRIES_STARTED,
});

export const getTopUpCountriesSuccessAction = (payload) => ({
  type: GET_TOP_UP_COUNTRIES_SUCCESS,
  payload,
});

export const getTopUpCountriesFailureAction = (message) => ({
  type: GET_TOP_UP_COUNTRIES_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getTopUpBanksAction = ({ countryId }) => {
  return async (dispatch) => {
    dispatch(getTopUpBanksStartedAction());
    try {
      let response = await getTopUpBanksAPI({ countryId });
      if (response.status === "success") {
        dispatch(getTopUpBanksSuccessAction({ topUpBanks: response.data }));
      } else {
        getTopUpBanksFailureAction(response.data.error_description);
      }
    } catch (error) {
      dispatch(getTopUpBanksFailureAction(error.error_description));
    }
  };
};

export const getTopUpBanksStartedAction = () => ({
  type: GET_TOP_UP_BANKS_STARTED,
});

export const getTopUpBanksSuccessAction = (payload) => ({
  type: GET_TOP_UP_BANKS_SUCCESS,
  payload,
});

export const getTopUpBanksFailureAction = (message) => ({
  type: GET_TOP_UP_BANKS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createTopUpIbanAction = ({
  currencyId,
  countryName,
  bankName,
  iban,
  holderName,
  onSuccess,
  setLoading,
}) => {
  return async (dispatch) => {
    dispatch(createTopUpIbanStartedAction());
    setLoading(true);
    try {
      let response = await createTopUpIbanAPI({
        currencyId,
        countryName,
        bankName,
        holderName,
        iban,
      });
      if (response.status === "success") {
        dispatch(createTopUpIbanSuccessAction({ topUpBanks: response.data }));
        setLoading(false);
        onSuccess();
      } else {
        setLoading(false);
        createTopUpIbanFailureAction(response.data.error_description);
      }
    } catch (error) {
      setLoading(false);
      dispatch(createTopUpIbanFailureAction(error.error_description));
    }
  };
};

export const createTopUpIbanStartedAction = () => ({
  type: CREATE_TOP_UP_IBAN_STARTED,
});

export const createTopUpIbanSuccessAction = (payload) => ({
  type: CREATE_TOP_UP_IBAN_SUCCESS,
  payload,
});

export const createTopUpIbanFailureAction = (message) => ({
  type: CREATE_TOP_UP_IBAN_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getTopUpIbanListAction = () => {
  return async (dispatch) => {
    dispatch(getTopUpIbanListStartedAction());
    try {
      let response = await getTopUpIbanListAPI();
      if (response.status === "success") {
        dispatch(getTopUpIbanListSuccessAction({ ibanList: response.data }));
      } else {
        getTopUpIbanListFailureAction(response.data.error_description);
      }
    } catch (error) {
      dispatch(getTopUpIbanListFailureAction(error.error_description));
    }
  };
};

export const getTopUpIbanListStartedAction = () => ({
  type: GET_TOP_UP_IBAN_LIST_STARTED,
});

export const getTopUpIbanListSuccessAction = (payload) => ({
  type: GET_TOP_UP_IBAN_LIST_SUCCESS,
  payload,
});

export const getTopUpIbanListFailureAction = (message) => ({
  type: GET_TOP_UP_IBAN_LIST_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const deleteTopUpIbanAction = ({ id }) => {
  return async (dispatch) => {
    dispatch(deleteTopUpIbanStartedAction());
    try {
      let response = await deleteTopUpIbanAPI({ id });
      if (response.status === "success") {
        dispatch(deleteTopUpIbanSuccessAction());
        dispatch(getTopUpIbanListAction());
      } else {
        deleteTopUpIbanFailureAction(response.data.error_description);
      }
    } catch (error) {
      dispatch(deleteTopUpIbanFailureAction(error.error_description));
    }
  };
};

export const deleteTopUpIbanStartedAction = () => ({
  type: DELETE_TOP_UP_IBAN_STARTED,
});

export const deleteTopUpIbanSuccessAction = (payload) => ({
  type: DELETE_TOP_UP_IBAN_SUCCESS,
  payload,
});

export const deleteTopUpIbanFailureAction = (message) => ({
  type: DELETE_TOP_UP_IBAN_FAILURE,
  payload: message,
});
