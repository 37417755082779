import { useSelector } from "react-redux";


const HasPermissions = ({ permissions, children }) => {
    
    const userPermissions = useSelector((state) => state.auth.user.permissions);

    const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];

    const hasPermission = permissionsArray.every(permission => {
        return userPermissions.includes(permission);
    });

    
    if (hasPermission) {
        return <>{children}</>;
    } else {
        return null; 
    }

};

export default HasPermissions;