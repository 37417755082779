import {
  CREATE_EXCHANGE_RATE_FAILURE,
  CREATE_EXCHANGE_RATE_STARTED,
  CREATE_EXCHANGE_RATE_SUCCESS,
  DELETE_EXCHANGE_RATE_FAILURE,
  DELETE_EXCHANGE_RATE_STARTED,
  DELETE_EXCHANGE_RATE_SUCCESS,
  GET_EXCHANGE_RATE_LIST_FAILURE,
  GET_EXCHANGE_RATE_LIST_STARTED,
  GET_EXCHANGE_RATE_LIST_SUCCESS,
} from "redux/ActionTypes";
import {
  createExchangeRateAPI,
  deleteExchangeRateAPI,
  getExchangeRateListAPI,
} from "services/API/exchange";
// --------------------------------------------------------------------------------
export const getExchangeRateListAction = () => {
  return async (dispatch) => {
    dispatch(getExchangeRateListStartedAction());
    try {
      let response = await getExchangeRateListAPI();
      if (response.status === "success") {
        dispatch(
          getExchangeRateListSuccessAction({ exchangeRateList: response.data })
        );
      } else {
        getExchangeRateListFailureAction(response.data.error_description);
      }
    } catch (error) {
      dispatch(getExchangeRateListFailureAction(error.error_description));
    }
  };
};

export const getExchangeRateListStartedAction = () => ({
  type: GET_EXCHANGE_RATE_LIST_STARTED,
});

export const getExchangeRateListSuccessAction = (payload) => ({
  type: GET_EXCHANGE_RATE_LIST_SUCCESS,
  payload,
});

export const getExchangeRateListFailureAction = (message) => ({
  type: GET_EXCHANGE_RATE_LIST_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createExchangeRateAction = ({
  sourceCurrencyId,
  targetCurrencyId,
  rate,
  onSuccess,
  setLoading,
}) => {
  return async (dispatch) => {
    dispatch(createExchangeRateStartedAction());
    setLoading(true);
    try {
      let response = await createExchangeRateAPI({
        sourceCurrencyId,
        targetCurrencyId,
        rate,
      });
      if (response.status === "success") {
        setLoading(false);
        dispatch(
          createExchangeRateSuccessAction({ exchangeRateList: response.data })
        );
        onSuccess();
      } else {
        setLoading(false);
        createExchangeRateFailureAction(response.data.error_description);
      }
    } catch (error) {
      setLoading(false);
      dispatch(createExchangeRateFailureAction(error.error_description));
    }
  };
};

export const createExchangeRateStartedAction = () => ({
  type: CREATE_EXCHANGE_RATE_STARTED,
});

export const createExchangeRateSuccessAction = (payload) => ({
  type: CREATE_EXCHANGE_RATE_SUCCESS,
  payload,
});

export const createExchangeRateFailureAction = (message) => ({
  type: CREATE_EXCHANGE_RATE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const deleteExchangeRateAction = ({ id }) => {
  return async (dispatch) => {
    dispatch(deleteExchangeRateStartedAction());
    try {
      let response = await deleteExchangeRateAPI({ id });
      if (response.status === "success") {
        dispatch(
          deleteExchangeRateSuccessAction({ exchangeRateList: response.data })
        );
        dispatch(getExchangeRateListAction());
      } else {
        deleteExchangeRateFailureAction(response.data.error_description);
      }
    } catch (error) {
      dispatch(deleteExchangeRateFailureAction(error.error_description));
    }
  };
};

export const deleteExchangeRateStartedAction = () => ({
  type: DELETE_EXCHANGE_RATE_STARTED,
});

export const deleteExchangeRateSuccessAction = (payload) => ({
  type: DELETE_EXCHANGE_RATE_SUCCESS,
  payload,
});

export const deleteExchangeRateFailureAction = (message) => ({
  type: DELETE_EXCHANGE_RATE_FAILURE,
  payload: message,
});
