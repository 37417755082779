import axios from "axios";
import { store } from "../../redux/store";
import { baseAPIAddress } from "./constants";
import { logoutSuccessAction } from "../../redux/actions/AuthActions";

const axiosMiddleware = axios.create({
  baseURL: `${baseAPIAddress}/api`,
  timeout: 60000,
  // You can set other default config options here
});

// Add a request interceptor
axiosMiddleware.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state.auth.token;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (config.trackUploadProgress) {
      config.onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (typeof config.trackUploadProgress === "function") {
          config.trackUploadProgress(percentCompleted);
        }
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosMiddleware.interceptors.response.use(
  (response) => {
    console.warn("axiosMiddleware response", {
      endpoint: response.config.url,
      response,
    });
    // Reset percentCompleted to 0 after request completes
    if (
      response.config.trackUploadProgress &&
      typeof response.config.trackUploadProgress === "function"
    ) {
      response.config.trackUploadProgress(0);
    }
    return response.data;
  },
  async (error) => {
    console.warn("axiosMiddleware error", {
      endpoint: error.config.url,
      error,
    });
    // Reset percentCompleted to 0 after request completes with error
    if (
      error.config.trackUploadProgress &&
      typeof error.config.trackUploadProgress === "function"
    ) {
      error.config.trackUploadProgress(0);
    }
    const originalRequest = error.config;

    // Handle token expiration and refresh
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const state = store.getState();
      const refreshToken = state.auth.refreshToken;

      if (refreshToken) {
        try {
          const newTokens = await axiosMiddleware({
            method: "post",
            url: "refresh",
            data: { refresh_token: refreshToken },
          });
          // Update tokens in local storage or state
          // authService.setAccessToken(newTokens.accessToken);
          // authService.setRefreshToken(newTokens.refreshToken);

          // Retry the original request with the new token
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${newTokens.accessToken}`;
          return axiosMiddleware(originalRequest);
        } catch (refreshError) {
          // Handle refresh error (e.g., logout user)
          store.dispatch(logoutSuccessAction());
        }
      } else {
        // Handle refresh token absence (e.g., logout user)
        store.dispatch(logoutSuccessAction());
      }
    }
    return Promise.reject(error.response.data);
  }
);

export default axiosMiddleware;
