import React, { Fragment, useEffect, useMemo, useState } from "react";
import MessageDialog, { CONFIRMATION_DIALOG } from "components/MessageDialog";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTopUpListRequestAction } from "redux/actions/topupActions";
import {
  deleteExchangeRateAction,
  getExchangeRateListAction,
} from "redux/actions/exchange";
import DeleteIcon from "@mui/icons-material/Delete";
import { MaterialReactTable } from "material-react-table";
import CustomTable from "components/CustomTable";
// --------------------------------------------------------------------------------
const ExchangeRateList = () => {
  const dispatch = useDispatch();

  const { exchangeRateList } = useSelector((state) => ({
    exchangeRateList: state.exchange.exchangeRateList,
  }));

  const [dialogProps, setDialogProps] = useState({ isOpen: false, type: "" });

  useEffect(() => {
    dispatch(getExchangeRateListAction());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "sourceCurrency",
        header: "Source Currency",
      },
      {
        accessorKey: "targetCurrency",
        header: "Target Currency",
      },
      {
        accessorKey: "rate",
        header: "Rate",
      },
    ],
    []
  );

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this rate?")) {
      dispatch(deleteExchangeRateAction({ id: row.original.id }));
    }
  };

  return (
    <CustomTable
      label="Exchange Rate List"
      data={exchangeRateList}
      columns={columns}
      enableRowActions
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      manualPagination={false}
    />
  );
};

export default ExchangeRateList;
