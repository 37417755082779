import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AppTextInput from "components/AppTextInput/index";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const useStyles = makeStyles({
  root: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : 300),
    // margin: 10,
  },
});

const AppDatePicker = ({
  label,
  value,
  onChange,
  variant = "outlined",
  fullWidth = false,
  readOnly = false,
  shrink = true,
  error = false,
  margin = "normal",
  dir = "ltr",
  disabled,
  required = false,
  onFocus,
  name,
  color = "primary",
  helperText,
  defaultValue,
  disablePast,
  disableFuture,
  ...otherProps
}) => {
  const classes = useStyles({ fullWidth });
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        name={name}
        value={value ?? defaultValue}
        label={label}
        className={classes.root}
        onFocus={onFocus}
        onChange={onChange}
        margin={margin}
        disablePast={disablePast}
        disableFuture={disableFuture}
        inputFormat="DD/MM/YYYY"
        views={["year", "month", "day"]}
        componentsProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
        renderInput={(params) => {
          return (
            <AppTextInput
              {...params}
              InputLabelProps={{
                shrink,
                readOnly,
              }}
              error={error}
              helperText={helperText}
              color={color}
              variant={variant}
              dir={dir}
              required={required}
            />
          );
        }}
        {...otherProps}
      />
    </LocalizationProvider>
  );
};

export default AppDatePicker;
