import React from "react";
import Button from "@mui/material/Button";

const FileUploader = ({ onChange, file, onFocus, onBlur, error }) => {
  return (
    <div className="d-flex flex-column align-items-center my-2">
      <Button
        onFocus={onFocus}
        onBlur={onBlur}
        sx={{ border: error ? "1px dashed red" : "1px dashed grey" }}
        className=" text-dark px-5 py-3"
        component="label"
      >
        <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
        Upload File
        <input type="file" onChange={onChange} hidden />
      </Button>
      {file && (
        <div className="my-2 ">
          File Name: <span className="text-success mr-2">{file?.name}</span>
          File Size:{" "}
          <span className="text-success">
            {Math.trunc(file?.size / 1024)}KB
          </span>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
