import axiosMiddleware from "./axiosMiddleware";

export const topupUserAPI = ({
  walletId,
  userId,
  amount,
  description,
  fee,
  file,
}) => {
  let postData = {
    walletId,
    userId,
    amount,
    description,
    file,
  };
  return axiosMiddleware({
    method: "post",
    url: "topup/create",
    data: postData,
  });
};

export const topupAdminAPI = ({
  walletId,
  adminId,
  amount,
  description,
  fee,
  file,
}) => {
  let postData = {
    wallet_id :walletId,
    admin_id : adminId,
    amount,
    description,
    file,
  };
  return axiosMiddleware({
    method: "post",
    url: "topup/create-admin",
    data: postData,
  });
};


export const getTopUpListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "topup/list",
  });
};

export const getAgentTopUpListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "topup/agent/list",
  });
};


export const getTopUpCountriesAPI = ({ walletId }) => {
  return axiosMiddleware({
    method: "get",
    url: `topup/get-country?currencyId=${walletId}`,
  });
};
export const getTopUpBanksAPI = ({ countryId }) => {
  return axiosMiddleware({
    method: "get",
    url: `topup/get-banks?countryId=${countryId}`,
  });
};
export const createTopUpIbanAPI = ({
  currencyId,
  countryName,
  bankName,
  iban,
  holderName,
}) => {
  let postData = {
    currencyId,
    countryName,
    bankName,
    iban,
    holderName,
  };
  return axiosMiddleware({
    method: "post",
    url: "topup/iban-create",
    data: postData,
  });
};
export const getTopUpIbanListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "topup/iban-list",
  });
};
export const deleteTopUpIbanAPI = ({ id }) => {
  let deleteData = {
    id,
  };
  return axiosMiddleware({
    method: "delete",
    url: "topup/iban-delete",
    data: deleteData,
  });
};
