import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getUsersSettingsAction,
  setUsersSettingsAction,
} from "redux/actions/usersActions";
import CardHeader from "components/CardHeader";
import AppSwitch from "components/AppSwitch";
import { CardContent, Grid, Button, CardActions } from "@mui/material";
import DropDown from "components/DropDown";
import { getTimeZonesAction } from "redux/actions/GeneralActions";

const UserSettings = () => {
  const { id: userId } = useParams();
  const dispatch = useDispatch();
  const [settings, setSettings] = useState({});
  const [timeZones, setTimeZones] = useState(null);

  const handleChange = (event, name) => {
    setSettings({
      ...settings,
      field: { ...settings.field, [name]: event.target.checked ? 1 : 0 },
    });
  };

  const generateTitle = (item) => {
    return (
      item.charAt(0).toUpperCase() +
      item.slice(1).replace(/[A-Z]/g, (m) => " " + m.toUpperCase())
    );
  };

  useEffect(() => {
    if (userId) {
      dispatch(getUsersSettingsAction(userId, setSettings));
      dispatch(getTimeZonesAction(setTimeZones));
    }
  }, [userId]);

  return (
    <div className="jr-card jr-full-card">
      <CardHeader title="settings" />
      <div className="p-3">
        <div className="jr-tabs-content jr-task-list">
          <div className="row">
            <div className="col-12"></div>
            <CardContent>
              <Grid container>
                <Grid xs={3}>
                  {timeZones && <DropDown
                    label="Time Zone"
                    schema={{ label: "timezone", value: "id" }}
                    items={timeZones}
                    value={settings.timezoneId}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        timezoneId: event.target.value,
                      })
                    }
                  />}
                </Grid>
                <Grid xs={9}></Grid>
                {settings.field &&
                  Object.keys(settings.field).map((item) => {
                    return (
                      <Grid xs={3}>
                        <AppSwitch
                          label={generateTitle(item)}
                          checked={settings.field[item]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(setUsersSettingsAction(settings));
                }}
              >
                Submit
              </Button>
            </CardActions>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
