import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCardDetails,
  getUserTransactions,
} from "redux/actions/usersActions";
import { Card, CardContent, Box, Grid, Typography, Link } from "@mui/material";
import { CardTitle } from "reactstrap";
import DataCard from "../../../../../components/DataCard";
import Dayjs from "dayjs";
import { getCardTransactions } from "redux/actions/usersActions";
import CardLimits from "./CardLimits";
import CardsStatus from "./CardsStatus";
import CustomTable from "components/CustomTable";
import withDateRangePicker from "HOC/withDateRangePicker";

// --------------------------------------------------------------------------------
const CardDetail = ({ from, to, renderTopToolbar }) => {
  const dispatch = useDispatch();
  const [card, setCard] = useState([]);
  const [showLimitsAndStatus, setShow] = useState(true);
  const { id: cardId } = useParams();
  const [data, setData] = useState({ records: [] });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const fetch = () => {
    dispatch(
      getUserTransactions({
        setData,
        cardId,
        from,
        to,
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  };

  useEffect(() => {
    if (cardId) dispatch(getCardDetails({ setCard, cardId }));
  }, [cardId]);

  useEffect(() => {
    if (card.type) {
      if (
        (card.type === "plastic" && card.status === "cancel") ||
        (card.type === "virtual" && card.status === "remove")
      ) {
        setShow(false);
      }
    }
  }, [card]);

  useEffect(() => {
    dispatch(
      getCardTransactions({
        setData,
        cardId,
        from: Dayjs(from).format("YYYY-MM-DD"),
        to: Dayjs(to).format("YYYY-MM-DD"),
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize, from, to]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "cardBalance",
        header: "Card Balance",
      },
      {
        accessorKey: "cardHolerName",
        header: "Card Name",
      },
      {
        accessorKey: "cardNumber",
        header: "Card Number",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "feeAmount",
        header: "Fee Amount",
      },
      {
        accessorKey: "mcc",
        header: "MMC",
      },
      {
        accessorKey: "originalAmount",
        header: "Original Amount",
      },
      {
        accessorKey: "originalCurrency",
        header: "Original Currency",
      },
      {
        accessorKey: "rrn",
        header: "RRN",
      },
      {
        accessorKey: "settlementAmount",
        header: "Settlement Amount",
      },
      {
        accessorKey: "settlementCurrency",
        header: "Settlement Currency",
      },
      {
        accessorKey: "terminalType",
        header: "Terminal Type",
      },
      {
        accessorKey: "transactionAmount",
        header: "Transaction Amount",
      },
      {
        accessorKey: "transactionCurrency",
        header: "Transaction Currency",
      },
      {
        accessorKey: "transactionDateTime",
        header: "Transaction Time",
      },
      {
        accessorKey: "transactionStatus",
        header: "Transaction Status",
      },
    ],
    []
  );

  return (
    <>
      <Card sx={{ p: 2, mb: 2 }}>
        <CardTitle>Card Detail</CardTitle>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>
                  Masked Card Number:{" "}
                </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  {card.cardNumber}
                </Typography>
              </Grid>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>Card Type: </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  {card.type}
                </Typography>
              </Grid>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>Card Brand: </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  {card.brand}
                </Typography>
              </Grid>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>
                  Card Status:{" "}
                </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  {card.status}
                </Typography>
              </Grid>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>
                  Created Time:{" "}
                </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  {card.createdTime}
                </Typography>
              </Grid>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>
                  Last Updated Time:{" "}
                </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  {card.lastUpdate}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>User Agent: </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  <Link
                    target={"_blank"}
                    href={`/app/profile/${card.userId}/Summary`}
                  >
                    {card.agent}
                  </Link>
                </Typography>
              </Grid>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>
                  User's Full Name:{" "}
                </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  <Link
                    target={"_blank"}
                    href={`/app/profile/${card.userId}/Summary`}
                  >
                    {card.user}
                  </Link>
                </Typography>
              </Grid>
              <Grid sx={{ mb: 2 }}>
                <Typography sx={{ display: "inline" }}>User Email: </Typography>
                <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                  <Link
                    target={"_blank"}
                    href={`/app/profile/${card.userId}/Summary`}
                  >
                    {card.email}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ p: 2, mb: 2 }}>
        <CardTitle>Card Balance</CardTitle>
        <CardContent>
          <Box sx={{ display: "flex", flexFlow: "wrap" }}>
            {"balance" in card &&
              card.balance.map((card) => {
                return <DataCard title={card.balance} subTitle={card.symbol} />;
              })}
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ mb: 2 }}>
        <CustomTable
          label="Card Transactions"
          renderTopToolbar={renderTopToolbar}
          columns={columns}
          data={data.records}
          onPaginationChange={setPagination}
          state={{ pagination, loading: false }}
          rowCount={data.count}
        />
      </Card>
      {showLimitsAndStatus ? (
        <>
          <CardLimits cardId={cardId} />
          <br />
          <CardsStatus cardId={cardId} setCard={setCard} />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default withDateRangePicker(CardDetail);
