import { useState } from "react";
import { changeStatusKycFieldRequestAction } from "redux/actions/customerActions";
import EditIcon from "@mui/icons-material/Edit";
import AppDropDown from "components/AppDropDown";
import AppTextInput from "components/AppTextInput";
import {
  Grid,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Typography,
} from "@mui/material/index";
import { useDispatch } from "react-redux";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { LoadingButton } from "@mui/lab/index";
import { snakeCaseToTitleCase } from "constants/usefulFunctions";
import { errorColor, primaryColor } from "assets/colors";

const KycField = ({ field, editable }) => {
  const dispatch = useDispatch();

  const { id: customerId } = useParams();

  const [edit, setEdit] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [approveLoading, setApproveLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);

  const statusItems = [{ label: "Other", value: "other" }];

  const renderConfirmDialog = (
    <Dialog open={isDeclined} onClose={() => setIsDeclined(false)}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppDropDown
              label="Reason"
              items={statusItems}
              onChange={(e) => setReason(e.target.value)}
              value={reason}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {reason === "other" && (
              <AppTextInput
                label="Description"
                onChange={(e) => setDescription(e.target.value)}
                multiline
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setIsDeclined(false);
            setEdit(false);
          }}
          fullWidth
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() =>
            dispatch(
              changeStatusKycFieldRequestAction({
                id: field.id,
                description: reason !== "other" ? reason : description,
                status: "Declined",
                customerId,
                setIsDeclined,
                setEdit,
                setLoading: setDeclineLoading,
              })
            )
          }
          loading={declineLoading}
          fullWidth
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", pt: 3, mb: 0.5 }}>
        <AppTextInput
          label={field.label}
          value={field.value}
          readOnly
          fullWidth
          endAdornment={
            field.status === "Approved" ? (
              <CheckCircleIcon color="success" />
            ) : field.status === "Declined" ? (
              <CancelIcon color="error" />
            ) : field.status === "Pending" ? (
              <ErrorIcon color="warning" />
            ) : null
          }
        />

        {field.status !== "Initial" && !edit && editable ? (
          <IconButton
            color="primary"
            onClick={() => setEdit(true)}
            sx={{ m: 0.5 }}
          >
            <EditIcon />
          </IconButton>
        ) : editable ? (
          <>
            <IconButton
              color="error"
              onClick={() => setIsDeclined(true)}
              size="small"
              sx={{ m: 0.5 }}
            >
              <CancelOutlinedIcon />
            </IconButton>
            <IconButton
              color="success"
              size="small"
              onClick={() =>
                dispatch(
                  changeStatusKycFieldRequestAction({
                    id: field.id,
                    status: "Approved",
                    customerId,
                    setIsDeclined,
                    setEdit,
                    setLoading: setApproveLoading,
                  })
                )
              }
              sx={{ m: 0.5 }}
            >
              {approveLoading ? (
                <CircularProgress size={21} color="success" />
              ) : (
                <CheckCircleOutlineIcon />
              )}
            </IconButton>
          </>
        ) : null}
        {renderConfirmDialog}
      </Box>
      <Typography sx={{ color: errorColor }}>{field.errorMessage}</Typography>
    </>
  );
};

export default KycField;
