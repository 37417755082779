import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  CircularProgress,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
// --------------------------------------------------------------------------------
export default function DropDown({
  name,
  label,
  items,
  disabled,
  value,
  onChange,
  helperText,
  error,
  schema,
  loading,
  ...otherProps
}) {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        MenuProps={{ sx: { maxHeight: 400 } }}
        disabled={disabled}
        notched
        startAdornment={
          loading && (
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          )
        }
        {...otherProps}
      >
        {items?.map((item) => (
          <MenuItem value={schema ? item[schema.value] : item}>
            {schema ? item[schema.label] : item}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
