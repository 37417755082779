import React, { Fragment, useEffect, useMemo, useState } from "react";
import MessageDialog, { CONFIRMATION_DIALOG } from "components/MessageDialog";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTopUpAgentListRequestAction, getTopUpListRequestAction } from "redux/actions/topupActions";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { downloadFileRequestAction } from "redux/actions/usersActions";
import FileSaver from "file-saver";
import { MaterialReactTable } from "material-react-table";
import CustomTable from "components/CustomTable";
// --------------------------------------------------------------------------------
const TopUpAgentList = () => {

 
  const [topupList, setTopupList] = useState([]);
  // const [agentList, setAgentList] = useState([]);
  // const [filteredTopupList, setFilteredTopupList] = useState([]);

  const dispatch = useDispatch();

  const { agentTopUpList } = useSelector((state) => ({
    agentTopUpList: state.agentTopup.agentTopUpList,
  }));

  const adminAgents = useSelector((state) => state.auth.user.agents);

  useEffect(() => {
    setTopupList(agentTopUpList);
    //setAgentList(adminAgents);
  }, [agentTopUpList, adminAgents]);

  // useEffect(() => {
  //   const agentNames = new Set(agentList.map(agent => agent.name));
  //   const filteredList = topupList.filter(topup => agentNames.has(topup.agent));
  //   setFilteredTopupList(filteredList);
  // }, [topupList, agentList]);

  const [dialogProps, setDialogProps] = useState({ isOpen: false, type: "" });

  useEffect(() => {
    dispatch(getTopUpAgentListRequestAction());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "user",
        header: "User's Full Name",
        // size: 150,
      },
      {
        accessorKey: "agent",
        header: "Agent",
        size: 150,
      },
      {
        accessorKey: "amount", //normal accessorKey
        header: "Amount",
        // size: 150,
      },
      {
        accessorKey: "currency",
        header: "Currency",
      },
      {
        accessorKey: "fee",
        header: "Fee",
      },
      {
        accessorKey: "date",
        header: "Date",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "file",
        header: "FIle",
        Cell: ({ cell, row }) =>
          row.original.file && (
            <IconButton
              onClick={() =>
                dispatch(
                  downloadFileRequestAction({
                    fileAddress: row.original.file,
                    onSuccess: (response) => {
                      let base64Data = `data:${response.data.type};base64,${response.data.value}`;
                      FileSaver.saveAs(
                        base64Data,
                        `WalletTopUp_${row.original.date}`
                      );
                    },
                  })
                )
              }
            >
              <AttachFileOutlinedIcon />
            </IconButton>
          ),
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Top-up Agent List"
      data={topupList}
      columns={columns}
      // onPaginationChange={undefined}
      // state={{ pagination, loading: false }}
      // rowCount={premiumUsers.count}
      manualPagination={false}
    />
  );
};

export default TopUpAgentList;
