import axiosMiddleware from "./axiosMiddleware";

export const getVirtualCardsListAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `/cards/list/virtual?limit=${limit}&offset=${offset}`,
  });
};
export const getPhysicalCardsListAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `/cards/list/physical?limit=${limit}&offset=${offset}`,
  });
};
export const getCardsLimitsAPI = (cardId) => {
  return axiosMiddleware({
    method: "post",
    url: "/card/get-limits",
    data: {
      cardId,
    },
  });
};
export const setCardsLimitsAPI = (data) => {
  return axiosMiddleware({
    method: "post",
    url: "/card/set-limits",
    data,
  });
};
export const updateCardsStatusAPI = (data) => {
  return axiosMiddleware({
    method: "post",
    url: "/card/update-status",
    data,
  });
};
export const getCardsStatusAPI = (cardId) => {
  return axiosMiddleware({
    method: "post",
    url: "/card/get-status",
    data: {
      cardId,
    },
  });
};
